// base font
$font-base: 16px;

// font size
$font-size-xs: 0.75rem;
$font-size-sm: 0.875rem;
$font-size-md: 1rem;
$font-size-lg: 1.125rem;
$font-size-xl: 1.5rem;
$font-size-xxl: 2rem;

// font family
$font-light: "IBMPlexSans light";
$font-regular: "IBMPlexSans regular";
$font-bold: "IBMPlexSans bold";


// form element variables
$input-padding: 1rem;
$select-padding: 0.8rem;
$input-height: 3.125rem;
$input-font-size: 1rem;
$input-line-height: 2rem;

//Background
$background-color-hover: #fef2f3;

// $background-color-dialog-title: #ffffff;

//Border
$border-primary: #e4e2e2;
$border-active: #ea1c2d;

//Text
$text-color-hover: #ea1c2d;

$button-text-color-secondary: #7d7373;

$link-color-primary: #38a2d1;

$checkbox-background-color: #ea1c2d;
// $checkbox-icon-color: #ffffff;

$text-color-danger: #ea1c2d;

/*
* Deprecated: Use nova instead
*/

$borderRadius: 0;
$disabledOpacity: 0.35;

//Header
$headerBorderWidth: 1px;
$headerBorderColor: #e4e2e2;
$headerBgColor: #333333;
$headerTextColor: #eeeeee;
$headerFontWeight: bold;
$headerIconTextColor: #eeeeee;

//Content
$contentBorderWidth: 1px;
// $contentBorderColor: $border-color-muted;
$contentTextColor: #eeeeee;

//Default State
$stateDefaultBorderWidth: 1px;
$stateDefaultBorderColor: $border-primary;
$stateDefaultBgColor: #3d3d3d;
$stateDefaultTextColor: #eeeeee;

//Active State
$stateActiveBorderColor: $border-primary;
$stateActiveBgColor: #ea1c2d;
// $stateActiveTextColor: #ffffff;

//Highlight State
$stateHighlightBorderColor: #cccccc;
$stateHighlightBgColor: #eeeeee;
$stateHighlightTextColor: #0099e0;

//Focus State
$stateFocusBorderColor: $border-primary;
$stateFocusBgColor: #474747;
// $stateFocusTextColor: #ffffff;

//Error State
$stateErrorBorderColor: #ffb73d;
$stateErrorBgColor: #ffc73d;
$stateErrorTextColor: #111111;

//Hover State
$stateHoverBorderColor: $background-color-hover;
$stateHoverBgColor: $background-color-hover;
$stateHoverTextColor: $text-color-hover;

//Forms
// $inputBgColor: #ffffff;
$inputTextColor: #222222;
$invalidInputBorderColor: #ffb73d;
// $inputGroupTextColor: #ffffff;
