@font-face {
    font-family: "IBMPlexSans bold";
    src: url("../assets/fonts/IBMPlexSans-Bold.ttf");
}

@font-face {
    font-family: "IBMPlexSans bold italic";
    src: url("../assets/fonts/IBMPlexSans-BoldItalic.ttf");
}

@font-face {
    font-family: "IBMPlexSans extralight";
    src: url("../assets/fonts/IBMPlexSans-ExtraLight.ttf");
}

@font-face {
    font-family: "IBMPlexSans extralight italic";
    src: url("../assets/fonts/IBMPlexSans-ExtraLightItalic.ttf");
}

@font-face {
    font-family: "IBMPlexSans italic";
    src: url("../assets/fonts/IBMPlexSans-Italic.ttf");
}

@font-face {
    font-family: "IBMPlexSans light";
    src: url("../assets/fonts/IBMPlexSans-Light.ttf");
}

@font-face {
    font-family: "IBMPlexSans lightitalic";
    src: url("../assets/fonts/IBMPlexSans-LightItalic.ttf");
}

@font-face {
    font-family: "IBMPlexSans medium";
    src: url("../assets/fonts/IBMPlexSans-Medium.ttf");
}

@font-face {
    font-family: "IBMPlexSans medium italic";
    src: url("../assets/fonts/IBMPlexSans-MediumItalic.ttf");
}

@font-face {
    font-family: "IBMPlexSans regular";
    src: url("../assets/fonts/IBMPlexSans-Regular.ttf");
}

@font-face {
    font-family: "IBMPlexSans semibold";
    src: url("../assets/fonts/IBMPlexSans-SemiBold.ttf");
}

@font-face {
    font-family: "IBMPlexSans semibold italic";
    src: url("../assets/fonts/IBMPlexSans-SemiBoldItalic.ttf");
}

@font-face {
    font-family: "IBMPlexSans thin";
    src: url("../assets/fonts/IBMPlexSans-Thin.ttf");
}

@font-face {
    font-family: "IBMPlexSans thin italic";
    src: url("../assets/fonts/IBMPlexSans-ThinItalic.ttf");
}
