@use "sass:math";

@import "theme-base";

body {
    overflow: hidden;

    .note-modal-backdrop {
        z-index: 0 !important;
    }
}

@media print {
    body * #invisibleForPrint {
        visibility: hidden !important;
    }

}

$diameter: 80px;
$spinner: 4px;
$spinner-color: var(--text-color-primary);
$icon-width: 10.6px;
$icon-height: 25px;

.progressBar{
    padding-top: 6em;
    color: white;
    font-weight: 800;
}

.full-page-loader {
    height: $diameter;
    width: $diameter;
    position: absolute;
    top: 50%;
    left: 50%;
    margin-top: math.div(-$diameter,2);
    margin-left: math.div(-$diameter,2);
    z-index: 5000;

    &:before {
        content: "";
        display: block;
        position: absolute;
        left: math.div(-$spinner,2);
        top: math.div(-$spinner,2);
        height: 100%;
        width: 100%;
        animation: rotation 1s linear infinite;
        border: math.div($spinner,2) solid $spinner-color;
        border-top: math.div($spinner,2) solid transparent;
        border-radius: 100%;
        z-index: 5001;
    }

    &>.icon {
        position: absolute;
        top: 50%;
        left: 50%;
        height: $icon-height;
        width: $icon-width;
        margin-top: math.div(-$icon-height,2);
        margin-left: math.div(-$icon-height,2);
        animation: fade 1s ease-in-out infinite alternate;
    }

    &+.page-loader-bg {
        width: 100vw;
        height: 100vh;
        background-color: rgba(0, 0, 0, 0.5);
        position: absolute;
        top: 0;
        left: 0;
        z-index: 4200;
    }
}

@keyframes rotation {
    from {
        transform: rotate(0deg);
    }

    to {
        transform: rotate(359deg);
    }
}

@keyframes fade {
    from {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
}

main {
    position: relative;
}

.p-scrollpanel-wrapper {
    background-color: rgb(235, 229, 229);
    // border-right: 9px solid red; 
}

.required {
    &:after {
        content: "*";
        color: #da481f;
    }
}

.infoIcon {
    float: right;
    margin-left: 10px;
    margin-top: -20px;
    position: relative;
    z-index: 2;
}

.text-subtitle {
    color: $button-text-color-secondary;
    font-size: 12px;
}

.main-content {

    // display: flex;
    // flex-direction: column;
    // width: 98vw;
    // height: 100vh;
    // .note-modal-backdrop {
    //     z-index: 0 !important;
    // }
    .page-container {
        background-color: white;
        padding-left: 15px;
        padding-right: 15px;
        margin: 10px;

    }

    .header-component {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        padding-top: 5px;
        padding-bottom: 5px;
        font-weight: bold;
        align-items: center;

        .dropdown-button {
            display: flex;
            flex-direction: row;
            align-items: center;

            .text {
                margin-right: 15px;
                font-weight: normal;
            }
        }

        button {
            // width: 60px;
            margin-right: 10px;
            outline: none;
            border: none;
        }

        button:focus {
            background-color: rgb(190, 188, 188);
        }
    }

    // .table {
    // .table-header {}
    // }


}
.disabled{
    background: #e9ecef;
}

.p-corner-all {
    -moz-border-radius: $borderRadius;
    -webkit-border-radius: $borderRadius;
    border-radius: $borderRadius;
}

.p-button-primary,.p-button-secondary,.p-dropdown,.p-multiselect{
    @extend .p-corner-all;
}

.p-datepicker {
    .p-datepicker-title {
        text-align: center;
    }
}

.p-breadcrumb {
    .p-menuitem-icon {
        margin-right: 0.5rem;
    }
}
