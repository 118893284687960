@charset "UTF-8";
@import '_variables-shared.scss';
@import "./././apps/core/src/styles/_fonts.scss";
@import "text-styles";

:root {

    --color-grey-darken-4: #323332;
    --color-grey-darken-3: #3F3D56;
    --color-grey-darken-2: #575757;
    --color-grey-darken-1: #6A7783;

    --color-grey: #65617D;

    --color-grey-lighten-1: #8D96A1;
    --color-grey-lighten-2: #9C9C9C;
    --color-grey-lighten-3: #968687;
    --color-grey-lighten-4: #CED5D4;
    --color-grey-lighten-5: #E8EAF2;
    --color-grey-lighten-6: #E6E8EC;
    --color-grey-lighten-7: #E2DFDE;

    --color-dark: #000000;

    --color-teal: #5B9B98;
    --color-teal-lighten-1: #EEF5F5;
    --color-blue-lighten-2: #EAEDF2;

    --color-blue-lighten-1: #29669A;
    --color-blue: #4A90E2;
    --color-blue-darken-1: #507ECC;
    --color-blue-darken-2: #084887;
    --color-blue-darken-3: #303D5C;
    --color-blue-darken-4: #3A3768;

    --color-blue-darken-5: #35507C;
    --color-blue-darken-6: #A6AAC7;
    --color-blue-darken-7: #74879F;

    // orange
    --color-orange-lighten-3: #F9E0DA;
    --color-orange-lighten-2: #EE8E9E;
    --color-orange-lighten-1: #EF2529;
    --color-orange: #DB4726;

    --color-white: #ffffff;
    --color-white-darken-1: #f4f4f4;

    --color-green: #51A566;
    --color-yellow: #d18538;
    --color-red: #F43E3E;
    --color-red-lighten: #f04f71;
    --color-red-lighten-1: #FDE7EC;

    --color-green-lighten: #377d64;
    --color-green-lighten-1: #e8f0ed;

    --login-btn-background-color: var(--color-teal-lighten-1);
    --login-btn-color: var(--color-teal);
    --create-account-link: var(--color-orange);

}

.fan-365-theme {

    .flat-btn {
        background-color: var(--background-color-white);
        border: 1px solid var(--border-color-muted);

        .p-button-label {
            color: var(--text-color-muted);
            font-weight: 500 !important;
        }

        &:hover {
            .p-button-label {
                color: var(--text-color-white);
            }
        }
    }

    .p-button {
        border-radius: 20px;

        &.p-inputgroup-addon {
            border-radius: 0;
        }
    }

    .p-button-primary {
        border-radius: 20px;
        border: 1px solid var(--border-color-primary) !important;

        &:hover {
            border: 1px solid var(--border-color-secondary) !important;
        }
    }

    background-color: #E8ECF1;
    --input-background-color-primary: var(--color-orange-lighten-3);
    --input-background-color-mute: var(--color-white-darken-1);

    --card-heading: var(--color-grey-darken-1);

    --text-color-primary: var(--color-orange-lighten-1);
    --text-color-secondary: var(--color-blue-darken-5);

    --text-color-danger: var(--color-red);
    --text-color-success: var(--color-green);
    --text-color-muted: var(--color-grey-lighten-1);
    --text-color-white: var(--color-white);
    --text-color-dark: var(--color-dark);
    --text-color-warning: var(--color-yellow);

    --table-link-color: var(--color-blue-lighten-1);

    --button-background-color-primary: var(--color-orange-lighten-1);
    --button-background-color-secondary: var(--color-blue-darken-5);

    --button-background-color-muted: var(--color-grey-lighten-1);
    --button-background-color-white: var(--color-white);
    --button-background-color-danger: var(--color-red);
    --button-background-color-success: var(--color-green);
    --button-background-color-dark: var(--color-dark);
    --button-background-color-warning: var(--color-yellow);

    --background-color-primary: var(--color-orange-lighten-1);
    --background-color-secondary: var(--color-blue-darken-5);

    --background-color-muted: var(--color-grey-lighten-1);
    --background-color-white: var(--color-white);
    --background-color-danger: var(--color-red);
    --background-color-success: var(--color-green);
    --background-color-dark: var(--color-dark);
    --background-color-warning: var(--color-yellow);
    --background-color-active: var(--color-orange-lighten-3);

    --border-color-primary: var(--color-orange-lighten-1);
    --border-color-secondary: var(--color-blue-darken-5);

    --border-color-muted: var(--color-grey-lighten-1);
    --border-color-white: var(--color-white);
    --border-color-danger: var(--color-red);
    --border-color-success: var(--color-green);
    --border-color-dark: var(--color-dark);
    --border-color-warning: var(--color-yellow);

    --input-color-primary: var(--color-orange-lighten-1);
    --input-color-secondary: var(--color-white-darken-1);

    --input-color-muted: var(--color-grey-lighten-6);
    --input-color-white: var(--color-white);
    --input-color-danger: var(--color-red);
    --input-color-success: var(--color-green);
    --input-color-dark: var(--color-dark);
    --input-color-warning: var(--color-yellow);

    --input-border-color: var(--color-grey-lighten-6);
    --dropdown-border-color: var(--color-grey-lighten-6);

    --checkbox-border-color: var(--color-orange-lighten-1);
    --checkbox-icon-color: var(--color-white);
    --background-color-card: var(--color-white);
    --background-color-table-header: var(--color-blue-lighten-2);
    --text-color-table-header: var(--color-blue-darken-7);
    --background-table-evenrow: var(--color-blue-lighten-2);
    --background-color-dialog-title: var(--color-white);
    --table-row-hover-color: var(--color-blue-lighten-2);
    --state-active-text-color: var(--color-white);

    --navbar-background-color: var(--color-orange-lighten-1);
    --navbar-text-color: var(--color-white);

    --link-color-secondary: var(--color-grey-lighten-3);
    --sortable-icon: var(--color-dark);

    --sub-heading-text: var(--color-grey-lighten-1);

    --upload-background-color: var(--color-blue-lighten-2);

    --package-button-background-color: var(--color-teal-lighten-1);

    --steper-border-color: var(--color-grey-lighten-5);

    --upload-delete-btn-color: var(--color-red-lighten-1);

}

.mogo-hub-theme {

    .flat-btn {
        background-color: var(--background-color-white);
        border: none;

        .p-button-label {
            color: var(--text-color-muted);
            font-weight: 500 !important;
        }

        &:hover {
            background-color: var(--background-color-primary) !important;

            .p-button-label {
                color: var(--text-color-white) !important;
            }
        }
    }

    background-color: #E8ECF1;
    --text-color-primary: var(--color-orange);
    --text-color-secondary: var(--color-teal);
    --text-color-danger: var(--color-red);
    --text-color-success: var(--color-green);
    --text-color-muted: var(--color-grey-lighten-1);
    --text-color-white: var(--color-white);
    --text-color-dark: var(--color-dark);
    --text-color-warning: var(--color-yellow);

    --button-background-color-primary: var(--color-orange);
    --button-background-color-muted: var(--color-grey-lighten-1);
    --button-background-color-secondary: var(--color-teal);
    --button-background-color-white: var(--color-white);
    --button-background-color-danger: var(--color-red);
    --button-background-color-success: var(--color-green);
    --button-background-color-dark: var(--color-dark);
    --button-background-color-warning: var(--color-yellow);

    --background-color-primary: var(--color-orange);
    --background-color-muted: var(--color-grey-lighten-1);
    --background-color-white: var(--color-white);
    --background-color-danger: var(--color-red);
    --background-color-success: var(--color-green);
    --background-color-dark: var(--color-dark);
    --background-color-warning: var(--color-yellow);
    --background-color-secondary: var(--color-teal);
    --background-color-active: var(--color-orange-lighten-3);

    --border-color-primary: var(--color-orange);
    --border-color-muted: var(--color-grey-lighten-1);
    --border-color-secondary: var(--color-teal);
    --border-color-white: var(--color-white);
    --border-color-danger: var(--color-red);
    --border-color-success: var(--color-green);
    --border-color-dark: var(--color-dark);
    --border-color-warning: var(--color-yellow);

    --input-color-primary: var(--color-orange);
    --input-color-secondary: var(--color-white-darken-1);
    --input-color-muted: var(--color-grey-lighten-1);
    --input-color-white: var(--color-white);
    --input-color-danger: var(--color-red);
    --input-color-success: var(--color-green);
    --input-color-dark: var(--color-dark);
    --input-color-warning: var(--color-yellow);

    --input-border-color: var(--color-grey-lighten-6);
    --dropdown-border-color: var(--color-grey-lighten-6);

    --text-color-table-header: var(--color-dark);

    --checkbox-border-color: var(--color-orange);
    --checkbox-icon-color: var(--color-white);
    --background-color-dialog-title: var(--color-white);
    --background-color-card: var(--color-white);
    --background-color-table-header: var(--color-grey-lighten-7);
    --background-table-padding: var(--color-orange-lighten-2);
    --background-table-evenrow: var(--color-grey-lighten-3);
    --table-row-hover-color: var(--color-grey-lighten-7);
    --state-active-text-color: var(--color-white);

    --navbar-background-color: var(--color-white);
    --navbar-text-color: var(--color-dark);
    --link-color-primary: var(--color-blue-lighten-1);
    --link-color-secondary: var(--color-teal);

    --sortable-icon: var(--color-dark);
    --sub-heading-text: var(--color-grey);

    --input-background-color-primary: var(--color-orange-lighten-3);
    --input-background-color-mute: var(--color-white-darken-1);
    --upload-background-color: var(--color-teal-lighten-1);
    --table-link-color: var(--color-blue);

    --steper-border-color: var(--color-grey-lighten-5);
    --package-button-background-color: var(--color-teal-lighten-1);

    --link-color-secondary: var(--color-blue-darken-1);
    --card-heading: var(--color-grey-darken-1);

    --upload-delete-btn-color: var(--color-red-lighten-1);
}

* {
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
}

@mixin button-element() {
    box-shadow: none;
}

@mixin hover-element() {
    border-color: $stateHoverBorderColor;
    background-color: $stateHoverBgColor;
    color: $stateHoverTextColor;

    a {
        color: $stateHoverTextColor;
    }
}

@mixin active-element() {
    // border-color: transparent transparent $border-active transparent;
    border-color: transparent transparent var(--border-color-primary);
    // background-color: var(--background-color-white);
    background-color: var(--background-color-white);
    // color: var(--text-color-dark);
    color: var(--text-color-dark);
}

@mixin text-red() {
    border-color: transparent;
    background-color: transparent;
    // color: var(--text-color-dark);
    color: var(--text-color-dark);
}

@mixin checkbox() {
    border-color: var(--border-color-muted);
    background-color: var(--background-color-white);
    color: var(--text-color-primary);
}

@mixin warning-element() {
    background-color: var(--button-background-color-warning) !important;
    border: none !important;

    &:focus,
    :active {
        background-color: var(--button-background-color-warning) !important;
        border: none !important;
        box-shadow: none !important;
        color: var(--text-color-dark) !important;
    }
}

@mixin cancel-element() {
    background-color: var(--button-background-color-white) !important;
    border: none !important;
    color: var(--text-color-dark) !important;

    &:focus,
    :active {
        background-color: var(--button-background-color-white) !important;
        border: none !important;
        box-shadow: none !important;
        color: var(--text-color-dark) !important;
    }
}

@mixin input-border() {
    border-top-color: var(--input-border-color);
    border-right-color: var(--input-border-color);
    border-bottom-color: var(--input-border-color);
    border-left-color: var(--input-border-color);
}

@mixin form-button {
    border-color: var(--input-border-color) var(--input-border-color) var(--input-border-color) var(--input-color-white) !important;
    background-color: var(--background-color-white) !important;
    color: var(--text-color-primary) !important;
}

body .p-component {
    // font-family: "Open Sans", "Helvetica Neue", sans-serif;
    font-size: 14px;
    text-decoration: none;
}

body .p-corner-all {
    -moz-border-radius: $borderRadius;
    -webkit-border-radius: $borderRadius;
    border-radius: $borderRadius;
}

body .p-corner-top {
    -moz-border-radius-topleft: $borderRadius;
    -webkit-border-top-left-radius: $borderRadius;
    border-top-left-radius: $borderRadius;
    -moz-border-radius-topright: $borderRadius;
    -webkit-border-top-right-radius: $borderRadius;
    border-top-right-radius: $borderRadius;
}

body .p-corner-bottom {
    -moz-border-radius-bottomleft: $borderRadius;
    -webkit-border-bottom-left-radius: $borderRadius;
    border-bottom-left-radius: $borderRadius;
    -moz-border-radius-bottomright: $borderRadius;
    -webkit-border-bottom-right-radius: $borderRadius;
    border-bottom-right-radius: $borderRadius;
}

body .p-corner-left {
    -moz-border-radius-topleft: $borderRadius;
    -webkit-border-top-left-radius: $borderRadius;
    border-top-left-radius: $borderRadius;
    -moz-border-radius-bottomleft: $borderRadius;
    -webkit-border-bottom-left-radius: $borderRadius;
    border-bottom-left-radius: $borderRadius;
}

body .p-corner-right {
    -moz-border-radius-topright: $borderRadius;
    -webkit-border-top-right-radius: $borderRadius;
    border-top-right-radius: $borderRadius;
    -moz-border-radius-bottomright: $borderRadius;
    -webkit-border-bottom-right-radius: $borderRadius;
    border-bottom-right-radius: $borderRadius;
}

body a {
    // color: var(--text-color-dark);
    color: var(--text-color-dark);
    text-decoration: none;
}

body a:hover {
    // color: var(--text-color-dark);
    color: var(--text-color-dark);
    text-decoration: none;
}

body a:active {
    // color: var(--text-color-dark);
    color: var(--text-color-dark);
    text-decoration: none;
}

body .p-reset {
    line-height: normal;
}

body .p-disabled,
body .p-component:disabled {
    opacity: 0.5;
    filter: alpha(opacity=50);
}

body .pi {
    font-size: 1.25em;
}



body .p-inputtext {
    font-size: 14px;
    // color: var(--text-color-dark);
    color: var(--text-color-dark) !important;
    // background: #ffffff;
    background-color: var(--background-color-white);
    padding: 0.429em;
    // border: 1px solid --border-color-muted;
    border: 1px solid var(--input-border-color);
    -moz-transition: border-color 0.2s;
    -o-transition: border-color 0.2s;
    -webkit-transition: border-color 0.2s;
    transition: border-color 0.2s;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
}

body .p-inputtext:enabled:hover:not(.p-state-error) {
    // border-color: --border-color-muted;
    border-color: var(--input-border-color);
}

body .p-inputtext:enabled:focus:not(.p-state-error) {
    @include input-border();
    outline: 0 none;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none;
}

body .p-inputtext:enabled:focus:not(.p-state-error)+.p-button {
    @include form-button();
    // color: var(--text-color-dark) !important;
    //color: var(--text-color-dark) !important;
}

body .p-inputtext:enabled:focus:not(.p-state-error)+.p-dropdown-filter-icon {
    // color: var(--text-color-dark) !important;
    color: var(--text-color-dark) !important;
}

body .p-checkbox {
    display: inline-block;
    vertical-align: middle;
    margin: 0;
    width: 20px;
    height: 20px;
    margin-top: 3px;
}

body .p-checkbox .p-checkbox-box {
    // border: 1px solid #a6a6a6;
    border: 1px solid var(--border-color-muted);
    // background-color: #ffffff;
    background-color: var(--background-color-white);
    width: 20px;
    height: 20px;
    text-align: center;
    -moz-border-radius: $borderRadius;
    -webkit-border-radius: $borderRadius;
    border-radius: $borderRadius;
    -moz-transition: background-color 0.2s, border-color 0.2s, box-shadow 0.2s;
    -o-transition: background-color 0.2s, border-color 0.2s, box-shadow 0.2s;
    -webkit-transition: background-color 0.2s, border-color 0.2s, box-shadow 0.2s;
    transition: background-color 0.2s, border-color 0.2s, box-shadow 0.2s;
}

body .p-checkbox .p-checkbox-box:not(.p-disabled):hover {
    // border-color: --border-color-muted;
    border-color: var(--border-color-muted);
}

body .p-checkbox .p-checkbox-box.p-focus {
    // border-color: #007ad9;
    border-color: var(--border-color-primary);
    // background-color: #ffffff;
    background-color: var(--background-color-white);
    // color: #007ad9;
    color: var(--text-color-primary);
    -webkit-box-shadow: 0 0 0 0.2em #8dcdff;
    -moz-box-shadow: 0 0 0 0.2em #8dcdff;
    box-shadow: 0 0 0 0.2em #8dcdff;
}

body .p-checkbox .p-checkbox-box.p-highlight {
    @include checkbox();
}

body .p-checkbox .p-checkbox-box.p-highlight:not(.p-disabled):hover {
    @include checkbox();
}

body .p-checkbox .p-checkbox-box.p-highlight.p-focus {
    @include checkbox();
}

body .p-checkbox .p-checkbox-box .p-checkbox-icon {
    overflow: hidden;
    position: relative;
    font-size: 18px;
    background-color: transparent!important;
    @include checkbox();
}

body .p-checkbox-label {
    margin: 0 0 0 0.5em;

}

body .p-radiobutton {
    display: inline-block;
    vertical-align: middle;
    margin: 0;
    width: 20px;
    height: 20px;
}

body .p-radiobutton .p-radiobutton-box {
    // border: 1px solid #a6a6a6;
    border: 1px solid var(--border-color-muted);
    // background-color: #ffffff;
    background-color: var(--background-color-white);
    width: 20px;
    height: 20px;
    text-align: center;
    position: relative;
    -moz-transition: background-color 0.2s, border-color 0.2s, box-shadow 0.2s;
    -o-transition: background-color 0.2s, border-color 0.2s, box-shadow 0.2s;
    -webkit-transition: background-color 0.2s, border-color 0.2s, box-shadow 0.2s;
    transition: background-color 0.2s, border-color 0.2s, box-shadow 0.2s;
    -moz-border-radius: 50%;
    -webkit-border-radius: 50%;
    border-radius: 50%;
}

body .p-radiobutton .p-radiobutton-box:not(.p-disabled):not(.p-highlight):hover {
    // border-color: #212121;
    border-color: var(--border-color-dark);
}

body .p-radiobutton .p-radiobutton-box.p-focus {
    // border-color: #007ad9;
    border-color: var(--border-color-primary);
    // background-color: #ffffff;
    background-color: var(--background-color-white);
    // color: #007ad9;
    color: var(--text-color-primary);
    -webkit-box-shadow: 0 0 0 0.2em #8dcdff;
    -moz-box-shadow: 0 0 0 0.2em #8dcdff;
    box-shadow: 0 0 0 0.2em #8dcdff;
}

body .p-radiobutton .p-radiobutton-box.p-highlight {
    // border-color: #007ad9;
    border-color: var(--border-color-primary);
    // background-color: #007ad9;
    background-color: var(--background-color-primary);
    // color: #ffffff;
    color: var(--text-color-white);
}

body .p-radiobutton .p-radiobutton-box.p-highlight .p-radiobutton-icon {
    // background-color: #ffffff;
    background-color: var(--background-color-white);
}

body .p-radiobutton .p-radiobutton-box.p-highlight:not(.p-disabled):hover {
    // border-color: #005b9f;
    border-color: var(--border-color-primary);
    // background-color: #005b9f;
    background-color: var(--background-color-primary);
    // color: #ffffff;
    color: var(--text-color-white);
}

body .p-radiobutton .p-radiobutton-box.p-highlight.p-focus {
    // border-color: #007ad9;
    border-color: var(--border-color-primary);
    // background-color: #007ad9;
    background-color: var(--background-color-primary);
    // color: #ffffff;
    color: var(--text-color-white);
}

body .p-radiobutton .p-radiobutton-box .p-radiobutton-icon {
    background: transparent;
    width: 10px;
    height: 10px;
    display: inline-block;
    position: absolute;
    left: 50%;
    top: 50%;
    margin-left: -5px;
    margin-top: -5px;
    -moz-transition: background-color 0.2s;
    -o-transition: background-color 0.2s;
    -webkit-transition: background-color 0.2s;
    transition: background-color 0.2s;
    -moz-border-radius: 50%;
    -webkit-border-radius: 50%;
    border-radius: 50%;
}

body .p-radiobutton .p-radiobutton-box .p-radiobutton-icon:before {
    display: none;
}

body .p-radiobutton-label {
    margin: 0 0 0 0.5em;
}

body .p-inputswitch {
    width: 3em;
    height: 1.75em;
}

body .p-inputswitch .p-inputswitch-slider {
    -moz-transition: background-color 0.2s;
    -o-transition: background-color 0.2s;
    -webkit-transition: background-color 0.2s;
    transition: background-color 0.2s;
    border-radius: 30px;
    background: #cccccc;
}

body .p-inputswitch .p-inputswitch-slider:before {
    background-color: var(--background-color-white);
    height: 1.25em;
    width: 1.25em;
    left: 0.25em;
    bottom: 0.25em;
    border-radius: 50%;
    -webkit-transition: 0.2s;
    transition: 0.2s;
    margin-top: -0.55rem;
}

body .p-inputswitch.p-inputswitch-checked .p-inputswitch-slider:before {
    -webkit-transform: translateX(1.25em);
    -ms-transform: translateX(1.25em);
    transform: translateX(1.25em);
}

body .p-inputswitch.p-inputswitch-focus .p-inputswitch-slider {
    // background: #b7b7b7;
    background: var(--background-color-muted);
}

body .p-inputswitch:not(.p-disabled):hover .p-inputswitch-slider {
    // background-color: #b7b7b7;
    background: var(--background-color-muted);
}

body .p-inputswitch.p-inputswitch-checked .p-inputswitch-slider {
    // background-color: #007ad9;
    background-color: var(--background-color-primary);
}

body .p-inputswitch.p-inputswitch-checked:not(.p-disabled):hover .p-inputswitch-slider {
    // background-color: #116fbf;
    background-color: var(--background-color-primary);
}

body .p-inputswitch.p-inputswitch-checked.p-inputswitch-focus .p-inputswitch-slider {
    // background: #005b9f;
    background-color: var(--background-color-primary);
}

body .p-autocomplete .p-autocomplete-input {
    padding: 0.429em;
}

body .p-autocomplete.p-autocomplete-multiple .p-autocomplete-multiple-container {
    padding: 0.2145em 0.429em;
}

body .p-autocomplete.p-autocomplete-multiple .p-autocomplete-multiple-container:not(.p-disabled):hover {
    // border-color: #212121;
    border-color: var(--border-color-dark);
}

body .p-autocomplete.p-autocomplete-multiple .p-autocomplete-multiple-container:not(.p-disabled).p-focus {
    // border-color: #007ad9;
    border: var(--border-color-primary);
    outline: 0 none;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none;
}

body .p-autocomplete.p-autocomplete-multiple .p-autocomplete-multiple-container .p-autocomplete-input-token {
    margin: 0;
    padding: 0.2145em 0;
    // color: var(--text-color-dark);
    color: var(--text-color-dark);
}

body .p-autocomplete.p-autocomplete-multiple .p-autocomplete-multiple-container .p-autocomplete-input-token input {
    // font-family: "Open Sans", "Helvetica Neue", sans-serif;
    font-size: 14px;
    padding: 0;
    margin: 0;
}

body .p-autocomplete.p-autocomplete-multiple .p-autocomplete-multiple-container .p-autocomplete-token {
    font-size: 14px;
    padding: 0.2145em 0.429em;
    margin: 0 0.286em 0 0;
    // background: #007ad9;
    background: var(--background-color-primary);
    // color: #ffffff;
    color: var(--text-color-white);
}

body .p-autocomplete-panel {
    padding: 0;
    // border: 1px solid #c8c8c8;
    border: 1px solid var(--border-color-muted);
    // background-color: #ffffff;
    background-color: var(--background-color-white);
    -webkit-box-shadow: 0 $borderRadius 6px 0 rgba(0, 0, 0, 0.16);
    -moz-box-shadow: 0 $borderRadius 6px 0 rgba(0, 0, 0, 0.16);
    box-shadow: 0 $borderRadius 6px 0 rgba(0, 0, 0, 0.16);
}

body .p-autocomplete-panel .p-autocomplete-items {
    padding: 0;
}

body .p-autocomplete-panel .p-autocomplete-items .p-autocomplete-list-item {
    margin: 0;
    padding: 0.429em 0.857em;
    border: 0 none;
    color: var(--text-color-dark);
    background-color: transparent;
    -moz-border-radius: 0;
    -webkit-border-radius: 0;
    border-radius: 0;
}

body .p-autocomplete-panel .p-autocomplete-items .p-autocomplete-list-item.p-highlight {
    // color: #ffffff;
    color: var(--text-color-white);
    // background-color: #007ad9;
    background-color: var(--background-color-primary);
}

body .p-autocomplete-panel .p-autocomplete-items .p-autocomplete-group {
    padding: 0.429em 0.857em;
    // background-color: #d8dae2;
    background-color: var(--background-color-muted);
    color: var(--text-color-dark);
}

body .p-fluid .p-autocomplete .p-autocomplete-dropdown.p-button {
    width: 2.357em;
}

body .p-fluid .p-autocomplete.p-autocomplete-multiple.p-autocomplete-dd .p-autocomplete-multiple-container {
    border-right: 0 none;
    width: calc(100% - 2.357em);
}

body .p-fluid .p-autocomplete.p-autocomplete-dd .p-inputtext {
    border-right: 0 none;
    width: calc(100% - 2.357em);
}

body .p-chips>ul.p-inputtext {
    padding: 0.2145em 0.429em;
    display: inline-block;
}

body .p-chips>ul.p-inputtext:not(.p-disabled):hover {
    border-color: #212121;
}

body .p-chips>ul.p-inputtext:not(.p-disabled).p-focus {
    border-color: #007ad9;
    outline: 0 none;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none;
}

body .p-chips>ul.p-inputtext .p-chips-input-token {
    padding: 0.2145em 0;
}

body .p-chips>ul.p-inputtext .p-chips-input-token input {
    // font-family: "Open Sans", "Helvetica Neue", sans-serif;
    font-size: 14px;
    padding: 0;
    margin: 0;
    color: var(--text-color-dark);
}

body .p-chips>ul.p-inputtext .p-chips-input-token input:hover {
    border: 0 none;
}

body .p-chips>ul.p-inputtext .p-chips-input-token input:focus {
    border: 0 none;
}

body .p-chips>ul.p-inputtext .p-chips-token {
    font-size: 14px;
    padding: 0.2145em 0.429em;
    margin: 0 0.286em 0 0;
    background: var(--background-color-primary);
    color: var(--text-color-white);
}

body .p-dropdown {
    background: var(--background-color-white);
    border: 1px solid var(--dropdown-border-color);
    -moz-transition: border-color 0.2s;
    -o-transition: border-color 0.2s;
    -webkit-transition: border-color 0.2s;
    transition: border-color 0.2s;
}

body .p-dropdown:not(.p-disabled):hover {
    border-color: var(--border-color-muted);
}

body .p-dropdown:not(.p-disabled).p-focus {
    @include input-border();
    outline: 0 none;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none;

    .p-dropdown-trigger {
        color: var(--text-color-dark);
    }
}

body .p-dropdown .p-dropdown-label {
    padding-right: 2em;
    background-color: transparent;
}

body .p-dropdown .p-dropdown-label {
    display: block;
    border: 0;
    white-space: nowrap;
    overflow: hidden;
    font-weight: normal;
    width: 100%; 
    padding-right:5em;
    font-weight: normal;
}

body .p-dropdown .p-dropdown-trigger {
    // background-color: #ffffff;
    background-color: var(--background-color-white);
    width: 2em;
    line-height: 2em;
    text-align: center;
    padding: 0;
    // color: #848484;
    color: var(--text-color-muted);
}

body .p-dropdown .p-dropdown-clear-icon {
    // color: #848484;
    color: var(--text-color-muted);
}

body .p-dropdown.p-dropdown-clearable .p-dropdown-label {
    padding-right: 4em;
}

body .p-dropdown-panel {
    padding: 0;
    border: 1px solid #c8c8c8;
    background-color: var(--background-color-white);
    -webkit-box-shadow: 0 $borderRadius 6px 0 rgba(0, 0, 0, 0.16);
    -moz-box-shadow: 0 $borderRadius 6px 0 rgba(0, 0, 0, 0.16);
    box-shadow: 0 $borderRadius 6px 0 rgba(0, 0, 0, 0.16);
}

body .p-dropdown-panel .p-dropdown-filter-container {
    padding: 0.429em 0.857em 0.429em 0.857em;
    border-bottom: 1px solid #eaeaea;
    color: var(--text-color-dark);
    // background-color: #ffffff;
    background-color: var(--background-color-white);
    margin: 0;
}

body .p-dropdown-panel .p-dropdown-filter-container .p-dropdown-filter {
    width: 100%;
    padding-right: 2em;
}

body .p-dropdown-panel .p-dropdown-filter-container .p-dropdown-filter-icon {
    top: 50%;
    margin-top: -0.5em;
    right: 1.357em;
    // color: var(--text-color-dark);
    color: var(--text-color-dark);
}

body .p-dropdown-panel .p-dropdown-items {
    padding: 0.5em 0 0.5em 0;
}

body .p-dropdown-panel .p-dropdown-items .p-dropdown-item,
body .p-dropdown-panel .p-dropdown-items .p-dropdown-item-group {
    margin: 0;
    padding: 0.429em 0.857em;
    border: 0 none;
    // color: var(--text-color-dark);
    color: var(--text-color-dark);
    background-color: transparent;
    -moz-border-radius: 0;
    -webkit-border-radius: 0;
    border-radius: 0;
    text-align: left;
}

body .p-dropdown-panel .p-dropdown-items .p-dropdown-item.p-highlight,
body .p-dropdown-panel .p-dropdown-items .p-dropdown-item-group.p-highlight {
    // color: var(--text-color-dark);
    color: var(--text-color-dark);
    background-color: $background-color-hover;
}

body .p-dropdown-panel .p-dropdown-items .p-dropdown-item:not(.p-highlight):not(.p-disabled):hover,
body .p-dropdown-panel .p-dropdown-items .p-dropdown-item-group:not(.p-highlight):not(.p-disabled):hover {
    @include hover-element();
}

body .p-multiselect {
    // background: #ffffff;
    background-color: var(--background-color-white);
    // border: 1px solid #a6a6a6;
    border: 1px solid var(--border-color-muted);
    -moz-transition: border-color 0.2s;
    -o-transition: border-color 0.2s;
    -webkit-transition: border-color 0.2s;
    transition: border-color 0.2s;
}

body .p-multiselect:not(.p-disabled):hover {
    // border-color: #212121;
    border-color: var(--border-color-dark);
}

body .p-multiselect:not(.p-disabled).p-focus {
    // border-color: #007ad9;
    border-color: var(--border-color-primary);
    outline: 0 none;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none;
}

body .p-multiselect .p-multiselect-label {
    padding: 0.429em;
    padding-right: 2em;
    // color: var(--text-color-dark);
    color: var(--text-color-dark);
}

body .p-multiselect .p-multiselect-trigger {
    // background-color: #ffffff;
    background-color: var(--background-color-white);
    width: 2em;
    line-height: 2em;
    text-align: center;
    padding: 0;
    // color: #848484;
    color: var(--text-color-muted);
}

body .p-multiselect-panel {
    padding: 0;
    // border: 1px solid #c8c8c8;
    border-color: 1px solid var(--border-color-muted);
    // background-color: #ffffff;
    background-color: var(--background-color-white);
    -webkit-box-shadow: 0 $borderRadius 6px 0 rgba(0, 0, 0, 0.16);
    -moz-box-shadow: 0 $borderRadius 6px 0 rgba(0, 0, 0, 0.16);
    box-shadow: 0 $borderRadius 6px 0 rgba(0, 0, 0, 0.16);
}

body .p-multiselect-panel .p-multiselect-header {
    padding: 0.429em 0.857em 0.429em 0.857em;
    border-bottom: 1px solid #eaeaea;
    color: var(--text-color-dark);
    // background-color: #ffffff;
    background-color: var(--background-color-white);
    margin: 0;
}

body .p-multiselect-panel .p-multiselect-header .p-multiselect-filter-container {
    float: none;
    width: 70%;
    display: inline-block;
    vertical-align: middle;
    margin-left: 0;
}

body .p-multiselect-panel .p-multiselect-header .p-multiselect-filter-container .p-inputtext {
    padding: 0.429em;
    padding-right: 2em;
}

body .p-multiselect-panel .p-multiselect-header .p-multiselect-filter-container .p-multiselect-filter-icon {
    // color: #007ad9;
    color: var(--text-color-primary);
    top: 50%;
    margin-top: -0.5em;
    right: 0.5em;
    left: auto;
}

body .p-multiselect-panel .p-multiselect-header .p-checkbox {
    margin-right: 0.5em;
    float: none;
    vertical-align: middle;
}

body .p-multiselect-panel .p-multiselect-header .p-multiselect-close {
    // color: #848484;
    color: var(--text-color-muted);
    top: 50%;
    margin-top: -0.5em;
    -moz-transition: color 0.2s;
    -o-transition: color 0.2s;
    -webkit-transition: color 0.2s;
    transition: color 0.2s;
}

body .p-multiselect-panel .p-multiselect-header .p-multiselect-close:hover {
    // color: var(--text-color-dark);
    color: var(--text-color-dark);
}

body .p-multiselect-panel .p-multiselect-items {
    padding: 0;
}

body .p-multiselect-panel .p-multiselect-items .p-multiselect-item {
    margin: 0;
    padding: 0.429em 0.857em;
    border: 0 none;
    // color: var(--text-color-dark);
    color: var(--text-color-dark);
    background-color: transparent;
    -moz-border-radius: 0;
    -webkit-border-radius: 0;
    border-radius: 0;
}

body .p-multiselect-panel .p-multiselect-items .p-multiselect-item.p-highlight {
    // color: #ffffff;
    color: var(--text-color-white);
    // background-color: #007ad9;
    background-color: var(--border-color-primary);
}

body .p-multiselect-panel .p-multiselect-items .p-multiselect-item:not(.p-highlight):not(.p-disabled):hover {
    color: var(--text-color-dark);
    // background-color: #eaeaea;
    background-color: var(--background-color-white);
}

body .p-multiselect-panel .p-multiselect-items .p-multiselect-item.p-disabled .p-checkbox-box {
    cursor: auto;
}

body .p-multiselect-panel .p-multiselect-items .p-multiselect-item.p-disabled .p-checkbox-box:hover {
    // border: 1px solid #a6a6a6;
    border: 1px solid var(--border-color-muted);
}

body .p-multiselect-panel .p-multiselect-items .p-multiselect-item.p-disabled .p-checkbox-box.p-highlight:hover {
    // border-color: #007ad9;
    border-color: var(--border-color-primary);
}

body .p-multiselect-panel .p-multiselect-items .p-multiselect-item .p-checkbox {
    position: static;
    display: inline-block;
    vertical-align: middle;
    margin: 0 0.5em 0 0;
}

body .p-multiselect-panel .p-multiselect-items .p-multiselect-item label {
    display: inline-block;
    vertical-align: middle;
}

body .p-listbox {
    padding: 0;
    min-width: 12em;
    // background: #ffffff;
    background: var(--background-color-white);
    // border: 1px solid #a6a6a6;
    border: 1px solid var(--border-color-muted);
}

body .p-listbox .p-checkbox {
    margin: 0 0.5em 0 0;
}

body .p-listbox .p-listbox-header {
    padding: 0.429em 0.857em 0.429em 0.857em;
    // border-bottom: 1px solid #eaeaea;
    border-bottom: 1px solid var(--border-color-white);
    // color: var(--text-color-dark);
    color: var(--text-color-dark);
    // background-color: #ffffff;
    background-color: var(--background-color-white);
    margin: 0;
}

body .p-listbox .p-listbox-header .p-listbox-filter-container {
    width: calc(100% - (0.857em + 0.857em + 0.5em));
}

body .p-listbox .p-listbox-header .p-listbox-filter-container input {
    padding: 0.429em;
    padding-right: 2em;
}

body .p-listbox .p-listbox-header .p-listbox-filter-container .p-listbox-filter-icon {
    top: 50%;
    left: auto;
    margin-top: -0.5em;
    right: 0.5em;
    // color: #007ad9;
    color: var(--text-color-primary);
}

body .p-listbox .p-listbox-list {
    // background-color: #ffffff;
    background-color: var(--background-color-primary);
}

body .p-listbox .p-listbox-list .p-listbox-item {
    margin: 0;
    padding: 0.429em 0.857em;
    border: 0 none;
    // color: var(--text-color-dark);
    color: var(--text-color-dark);
    background-color: transparent;
    -moz-border-radius: 0;
    -webkit-border-radius: 0;
    border-radius: 0;
}

body .p-listbox .p-listbox-list .p-listbox-item.p-highlight {
    @include hover-element();
}

body .p-listbox .p-listbox-list .p-listbox-item .p-checkbox {
    position: static;
    display: inline-block;
    vertical-align: middle;
    margin: 0 0.5em 0 0;
}

body .p-listbox .p-listbox-list .p-listbox-item label {
    display: inline-block;
    vertical-align: middle;
}

body .p-listbox:not(.p-disabled) .p-listbox-item:not(.p-highlight):not(.p-disabled):hover {
    @include hover-element();
    // border-color: --border-color-muted;
    border-color: var(--border-color-muted);

    .p-checkbox {
        .p-checkbox-box {
            // border-color: --border-color-muted;
            border-color: var(--border-color-muted);
        }
    }
}

body .p-listbox.p-disabled .p-checkbox-box:not(.p-disabled):not(.p-highlight):hover {
    // border: 1px solid #a6a6a6;
    border: 1px solid var(--border-color-muted);
}

body .p-listbox .p-listbox-footer {
    padding: 0.429em 0.857em 0.429em 0.857em;
    // border-top: 1px solid #eaeaea;
    border-top: 1px solid var(--border-color-white);
    color: var(--text-color-dark);
    // background-color: #ffffff;
    background-color: var(--background-color-white);
}

body .p-editor-container .p-editor-toolbar {
    // border: 1px solid #c8c8c8;
    border: 1px solid var(--border-color-muted);
    // background-color: #f4f4f4;
    background-color: var(--background-color-white);
}

body .p-editor-container .p-editor-content {
    // border: 1px solid #c8c8c8;
    border: 1px solid var(--border-color-muted);
}

body .p-editor-container .p-editor-content .ql-editor {
    // background-color: #ffffff;
    background-color: var(--background-color-white);
    // color: var(--text-color-dark);
    color: var(--text-color-dark);
}

body .p-editor-container .ql-picker.ql-expanded .ql-picker-label {
    // color: var(--text-color-dark);
    color: var(--text-color-dark);
}

body .p-editor-container .ql-stroke {
    // stroke: var(--text-color-dark);
    stroke: var(--text-color-dark);
}

body .p-editor-container .ql-picker-label {
    // color: var(--text-color-dark);
    color: var(--text-color-dark);
}

body .p-editor-container .ql-snow.ql-toolbar button:hover,
body .p-editor-container .ql-snow .ql-toolbar button:hover,
body .p-editor-container .ql-snow.ql-toolbar button.ql-active,
body .p-editor-container .ql-snow .ql-toolbar button.ql-active,
body .p-editor-container .ql-snow.ql-toolbar .ql-picker-label:hover,
body .p-editor-container .ql-snow .ql-toolbar .ql-picker-label:hover,
body .p-editor-container .ql-snow.ql-toolbar .ql-picker-label.ql-active,
body .p-editor-container .ql-snow .ql-toolbar .ql-picker-label.ql-active,
body .p-editor-container .ql-snow.ql-toolbar .ql-picker-item:hover,
body .p-editor-container .ql-snow .ql-toolbar .ql-picker-item:hover,
body .p-editor-container .ql-snow.ql-toolbar .ql-picker-item.ql-selected,
body .p-editor-container .ql-snow .ql-toolbar .ql-picker-item.ql-selected {
    // color: #007ad9;
    color: var(--text-color-primary);
}

body .p-editor-container .ql-snow.ql-toolbar button:hover .ql-stroke,
body .p-editor-container .ql-snow .ql-toolbar button:hover .ql-stroke,
body .p-editor-container .ql-snow.ql-toolbar button.ql-active .ql-stroke,
body .p-editor-container .ql-snow .ql-toolbar button.ql-active .ql-stroke,
body .p-editor-container .ql-snow.ql-toolbar .ql-picker-label:hover .ql-stroke,
body .p-editor-container .ql-snow .ql-toolbar .ql-picker-label:hover .ql-stroke,
body .p-editor-container .ql-snow.ql-toolbar .ql-picker-label.ql-active .ql-stroke,
body .p-editor-container .ql-snow .ql-toolbar .ql-picker-label.ql-active .ql-stroke,
body .p-editor-container .ql-snow.ql-toolbar .ql-picker-item:hover .ql-stroke,
body .p-editor-container .ql-snow .ql-toolbar .ql-picker-item:hover .ql-stroke,
body .p-editor-container .ql-snow.ql-toolbar .ql-picker-item.ql-selected .ql-stroke,
body .p-editor-container .ql-snow .ql-toolbar .ql-picker-item.ql-selected .ql-stroke,
body .p-editor-container .ql-snow.ql-toolbar button:hover .ql-stroke-miter,
body .p-editor-container .ql-snow .ql-toolbar button:hover .ql-stroke-miter,
body .p-editor-container .ql-snow.ql-toolbar button.ql-active .ql-stroke-miter,
body .p-editor-container .ql-snow .ql-toolbar button.ql-active .ql-stroke-miter,
body .p-editor-container .ql-snow.ql-toolbar .ql-picker-label:hover .ql-stroke-miter,
body .p-editor-container .ql-snow .ql-toolbar .ql-picker-label:hover .ql-stroke-miter,
body .p-editor-container .ql-snow.ql-toolbar .ql-picker-label.ql-active .ql-stroke-miter,
body .p-editor-container .ql-snow .ql-toolbar .ql-picker-label.ql-active .ql-stroke-miter,
body .p-editor-container .ql-snow.ql-toolbar .ql-picker-item:hover .ql-stroke-miter,
body .p-editor-container .ql-snow .ql-toolbar .ql-picker-item:hover .ql-stroke-miter,
body .p-editor-container .ql-snow.ql-toolbar .ql-picker-item.ql-selected .ql-stroke-miter,
body .p-editor-container .ql-snow .ql-toolbar .ql-picker-item.ql-selected .ql-stroke-miter {
    // stroke: #007ad9;
    stroke: var(--text-color-primary);
}

body .p-rating a {
    text-align: center;
    display: inline-block;
    // color: var(--text-color-dark);
    color: var(--text-color-dark);
    -moz-transition: color 0.2s;
    -o-transition: color 0.2s;
    -webkit-transition: color 0.2s;
    transition: color 0.2s;
}

body .p-rating a.p-rating-cancel {
    // color: #e4018d;
    color: var(--text-color-primary);
}

body .p-rating a .p-rating-icon {
    font-size: 20px;
}

body .p-rating:not(.p-disabled):not(.p-rating-readonly) a:hover {
    // color: #007ad9;
    color: var(--text-color-primary);
}

body .p-rating:not(.p-disabled):not(.p-rating-readonly) a.p-rating-cancel:hover {
    // color: #b5019f;
    color: var(--text-color-secondary);
}

body .p-spinner .p-spinner-input {
    padding-right: 2.429em;
}

body .p-spinner .p-spinner-button {
    width: 2em;
}

body .p-spinner .p-spinner-button.p-spinner-up {
    -moz-border-radius-topright: $borderRadius;
    -webkit-border-top-right-radius: $borderRadius;
    border-top-right-radius: $borderRadius;
}

body .p-spinner .p-spinner-button.p-spinner-down {
    -moz-border-radius-bottomright: $borderRadius;
    -webkit-border-bottom-right-radius: $borderRadius;
    border-bottom-right-radius: $borderRadius;
}

body .p-spinner .p-spinner-button .p-spinner-button-icon {
    position: absolute;
    top: 50%;
    left: 50%;
    margin-top: -0.5em;
    margin-left: -0.5em;
    width: 1em;
}

body .p-fluid .p-spinner .p-spinner-input {
    padding-right: 2.429em;
}

body .p-fluid .p-spinner .p-spinner-button {
    width: 2em;
}

body .p-fluid .p-spinner .p-spinner-button .p-spinner-button-icon {
    left: 50%;
}

body .p-slider {
    // background-color: #c8c8c8;
    border-color: var(--border-color-muted);
    border: 0 none;
}

body .p-slider.p-slider-horizontal {
    height: 0.286em;
}

body .p-slider.p-slider-horizontal .p-slider-handle {
    top: 50%;
    margin-top: -0.5715em;
}

body .p-slider.p-slider-vertical {
    width: 0.286em;
}

body .p-slider.p-slider-vertical .p-slider-handle {
    left: 50%;
    margin-left: -0.5715em;
}

body .p-slider .p-slider-handle {
    height: 1.143em;
    width: 1.143em;
    // background-color: #ffffff;
    background-color: var(--background-color-white);
    // border: 2px solid #666666;
    border: 2px solid var(--border-color-muted);
    -moz-border-radius: 100%;
    -webkit-border-radius: 100%;
    border-radius: 100%;
    -moz-transition: border-color 0.2s;
    -o-transition: border-color 0.2s;
    -webkit-transition: border-color 0.2s;
    transition: border-color 0.2s;
}

body .p-slider .p-slider-range {
    // background-color: var(--button-background-color-primary);
    background-color: var(--background-color-primary);
}

body .p-slider:not(.p-disabled) .p-slider-handle:hover {
    // background-color: 2px solid #666666;
    // border-color: 2px solid var(--border-color-muted);
    border: 2px solid var(--button-background-color-primary);
}

body .p-datepicker {
    padding: 0.857em;
    min-width: 20em;
    // background-color: #ffffff;
    background-color: var(--background-color-white);
    // color: var(--text-color-dark);
    color: var(--text-color-dark);
    border: 1px solid #a6a6a6;
}

body .p-datepicker .p-datepicker-title {
    width: 100%;
}

body .p-datepicker:not(.p-datepicker-inline) {
    border: 1px solid #c8c8c8;
    -webkit-box-shadow: 0 $borderRadius 6px 0 rgba(0, 0, 0, 0.16);
    -moz-box-shadow: 0 $borderRadius 6px 0 rgba(0, 0, 0, 0.16);
    box-shadow: 0 $borderRadius 6px 0 rgba(0, 0, 0, 0.16);
}

body .p-datepicker:not(.p-disabled) .p-datepicker-header .p-datepicker-prev:hover,
body .p-datepicker:not(.p-disabled) .p-datepicker-header .p-datepicker-next:hover {
    // color: #007ad9;
    color: var(--text-color-primary);
}

body .p-datepicker:not(.p-disabled) .p-datepicker-header .p-datepicker-prev:focus,
body .p-datepicker:not(.p-disabled) .p-datepicker-header .p-datepicker-next:focus {
    outline: 0 none;
    // color: #007ad9;
    color: var(--text-color-primary);
}

body .p-datepicker:not(.p-disabled) table td a:not(.p-highlight):not(.p-highlight):hover {
    // background-color: $text-color-primary;
    background-color: var(--background-color-primary);
    // color: #fff !important;
    color: var(--text-color-white);
}

body .p-datepicker:not(.p-disabled) .p-monthpicker a.p-monthpicker-month:not(.p-highlight):hover {
    background-color: #eaeaea;
}

body .p-datepicker .p-datepicker-header {
    padding: 0.429em 0.857em 0.429em 0.857em;
    // background-color: #ffffff;
    background-color: var(--text-color-white);
    // color: var(--text-color-dark);
    color: var(--text-color-dark);
    -moz-border-radius: 0;
    -webkit-border-radius: 0;
    border-radius: 0;
    border: none;
}

body .p-datepicker .p-datepicker-header span {
    line-height: 1;
}

body .p-datepicker .p-datepicker-header .p-datepicker-prev,
body .p-datepicker .p-datepicker-header .p-datepicker-next {
    cursor: pointer;
    top: 0;
    // color: #212121;
    color: var(--text-color-dark);
    -moz-transition: color 0.2s;
    -o-transition: color 0.2s;
    -webkit-transition: color 0.2s;
    transition: color 0.2s;
}

body .p-datepicker .p-datepicker-header .p-datepicker-title {
    margin: 0;
    padding: 0;
    line-height: 1;
    font-weight: 500;
    font-size: 1rem;
    // color: #212121 !important;
    color: var(--text-color-dark) !important;
}

body .p-datepicker .p-datepicker-header .p-datepicker-title select {
    margin-top: -0.35em;
    margin-bottom: 0;
}

body .p-datepicker table {
    font-size: 14px;
    margin: 0.857em 0 0 0;
}

body .p-datepicker table th {
    padding: 0.5em;
    text-transform: uppercase;
    // color: $text-color-secondary;
    color: var(--text-color-secondary);
    font-weight: 500;

    span {
        line-height: 1;
    }
}

body .p-datepicker table th.p-datepicker-weekheader {
    border-right: 1px solid #a6a6a6;
}

body .p-datepicker table td {
    padding-left: 0.5rem;
    padding-right: 0.5rem;
}

body .p-datepicker table td>a,
body .p-datepicker table td>span {
    display: block;
    text-align: center;
    // color: var(--text-color-dark);
    color: var(--text-color-dark);
    padding: 0.5em;
    -moz-border-radius: $borderRadius;
    -webkit-border-radius: $borderRadius;
    border-radius: $borderRadius;
}

body .p-datepicker table td>a.p-highlight,
body .p-datepicker table td>span.p-highlight {
    color: var(--text-color-white) !important;
    // background-color: $text-color-primary;
    background-color: var(--background-color-primary);
}

body .p-datepicker table td>span.p-disabled {
    // background-color: #ffffff !important;
    background-color: var(--background-color-white);
}

body .p-datepicker table td>a {
    cursor: pointer;
}

body .p-datepicker table td.p-datepicker-today>a,
body .p-datepicker table td.p-datepicker-today>span {
    // background-color: $text-color-primary!important;
    background-color: var(--text-color-primary);
    // color: #fff !important;
    color: var(--text-color-white);
}

body .p-datepicker table td>a,
body .p-datepicker table td>span {
    line-height: 1 !important;
    padding: 0.5rem !important;
}

body .p-datepicker table td>a {
    // background-color: #fff;
    background-color: var(--background-color-white);
    // color: #212121 !important;
    color: var(--background-color-dark);
}

body .p-datepicker table td.p-datepicker-today>a.p-highlight,
body .p-datepicker table td.p-datepicker-today>span.p-highlight {
    // color: #ffffff;
    color: var(--text-color-white);
    // background-color: #007ad9;
    background-color: var(--background-color-primary);
}

body .p-datepicker table td.p-datepicker-weeknumber {
    // border-right: 1px solid #a6a6a6;
    border-right: 1px solid var(--border-color-muted);
}

body .p-datepicker .p-datepicker-buttonbar {
    // border-top: 1px solid #d8dae2;
    border-top: 1px solid var(--border-color-muted);
}

body .p-datepicker .p-timepicker {
    border: 0 none;
    // border-top: 1px solid #d8dae2;
    border-top: 1px solid var(--border-color-muted);
    padding: 0.857em;
}

body .p-datepicker .p-timepicker a {
    // color: var(--text-color-dark);
    color: var(--text-color-dark);
    font-size: 1.286em;
}

body .p-datepicker .p-timepicker a:hover {
    // color: #007ad9;
    color: var(--text-color-secondary);
}

body .p-datepicker .p-timepicker span {
    font-size: 1.286em;
}

body .p-datepicker .p-monthpicker .p-monthpicker-month {
    // color: var(--text-color-dark);
    color: var(--text-color-dark);
}

body .p-datepicker .p-monthpicker .p-monthpicker-month.p-highlight {
    // color: #ffffff;
    color: var(--text-color-white);
    // background-color: #007ad9;
    background-color: var(--background-color-primary);
}

body .p-datepicker.p-datepicker-timeonly {
    padding: 0;
}

body .p-datepicker.p-datepicker-timeonly .p-timepicker {
    border-top: 0 none;
}

body .p-datepicker.p-datepicker-multiple-month .p-datepicker-group {
    // border-right: 1px solid #d8dae2;
    border-right: 1px solid var(--border-color-muted);
    padding-right: 0.857em;
    padding-left: 0.857em;
    padding-top: 0;
    padding-bottom: 0;
}

body .p-datepicker.p-datepicker-multiple-month .p-datepicker-group:first-child {
    padding-left: 0;
}

body .p-datepicker.p-datepicker-multiple-month .p-datepicker-group:last-child {
    padding-right: 0;
    border-right: 0 none;
}

body .p-calendar.p-calendar-w-btn .p-inputtext {
    -moz-border-radius-topright: 0;
    -webkit-border-top-right-radius: 0;
    border-top-right-radius: 0;
    -moz-border-radius-bottomright: 0;
    -webkit-border-bottom-right-radius: 0;
    border-bottom-right-radius: 0;
    border-right: 0 none;
}

body .p-calendar.p-calendar-w-btn .p-inputtext:enabled:hover:not(.p-state-error),
body .p-calendar.p-calendar-w-btn .p-inputtext:enabled:focus:not(.p-state-error) {
    border-right: 0 none;
}

body .p-calendar.p-calendar-w-btn .p-datepicker-trigger.p-button {
    width: 2.357em;
    -moz-border-radius-topleft: 0;
    -webkit-border-top-left-radius: 0;
    border-top-left-radius: 0;
    -moz-border-radius-bottomleft: 0;
    -webkit-border-bottom-left-radius: 0;
    border-bottom-left-radius: 0;
}

body .p-fluid .p-calendar.p-calendar-w-btn input.p-inputtext {
    width: calc(100% - 2.357em);
}

body .p-fileupload .p-fileupload-buttonbar {
    // background-color: #f4f4f4;

    padding: 0.571em 1em;
    border: 1px solid #c8c8c8;
    color: var(--text-color-dark);
    border-bottom: 0 none;
}

body .p-fileupload .p-fileupload-buttonbar .p-button {
    margin-right: 8px;
}

body .p-fileupload .p-fileupload-content {
    background-color: var(--background-color-white);
    padding: 0.571em 1em;
    border: 1px solid #c8c8c8;
    color: var(--text-color-dark);
}

body .p-fileupload .p-progressbar {
    top: 0;
}

body .p-fileupload-choose:not(.p-disabled):hover {
    background-color: var(--background-color-primary);
    color: var(--text-color-white);
    border-color: var(--border-color-muted);
}

body .p-fileupload-choose:not(.p-disabled):active {
    background-color: var(--background-color-primary);
    color: var(--text-color-white);
    border-color: var(--border-color-muted);
}

body .p-fileupload-choose.p-focus {
    outline: 0 none;
    outline-offset: 0px;
}

body .p-password-panel {
    padding: 12px;
    background-color: var(--background-color-white);
    color: var(--text-color-dark);
    border: 1px solid #c8c8c8;
    -webkit-box-shadow: 0 $borderRadius 6px 0 rgba(0, 0, 0, 0.16);
    -moz-box-shadow: 0 $borderRadius 6px 0 rgba(0, 0, 0, 0.16);
    box-shadow: 0 $borderRadius 6px 0 rgba(0, 0, 0, 0.16);
}

body .p-inputgroup .p-inputgroup-addon {
    border-color: var(--input-border-color) var(--input-border-color) var(--input-border-color) transparent;
    background-color: var(--background-color-white);
    color: var(--text-color-dark);
    padding: 0.429em;
    min-width: 2em;
}

body .p-inputgroup {
    .p-inputgroup-addon, input {
        &:first-child {
            -moz-border-radius-topleft: $borderRadius;
            -webkit-border-top-left-radius: $borderRadius;
            border-top-left-radius: $borderRadius;
            -moz-border-radius-bottomleft: $borderRadius;
            -webkit-border-bottom-left-radius: $borderRadius;
            border-bottom-left-radius: $borderRadius;
        }
    }
}

body .p-inputgroup .p-inputgroup-addon:last-child {
    -moz-border-radius-topright: $borderRadius;
    -webkit-border-top-right-radius: $borderRadius;
    border-top-right-radius: $borderRadius;
    -moz-border-radius-bottomright: $borderRadius;
    -webkit-border-bottom-right-radius: $borderRadius;
    border-bottom-right-radius: $borderRadius;
}

body .p-inputgroup .p-inputgroup-addon.p-inputgroup-addon-checkbox {
    position: relative;
}

body .p-inputgroup .p-inputgroup-addon.p-inputgroup-addon-checkbox .p-checkbox {
    position: absolute;
    top: 50%;
    left: 50%;
    margin-left: -10px;
    margin-top: -10px;
}

body .p-inputgroup .p-inputgroup-addon.p-inputgroup-addon-radiobutton {
    position: relative;
}

body .p-inputgroup .p-inputgroup-addon.p-inputgroup-addon-radiobutton .p-radiobutton {
    position: absolute;
    top: 50%;
    left: 50%;
    margin-left: -10px;
    margin-top: -10px;
}

body .p-inputgroup .p-button:first-child {
    -moz-border-radius-topleft: $borderRadius;
    -webkit-border-top-left-radius: $borderRadius;
    border-top-left-radius: $borderRadius;
    -moz-border-radius-bottomleft: $borderRadius;
    -webkit-border-bottom-left-radius: $borderRadius;
    border-bottom-left-radius: $borderRadius;
}

body .p-inputgroup .p-button:last-child {
    @include form-button();
    -moz-border-radius-topright: $borderRadius;
    -webkit-border-top-right-radius: $borderRadius;
    border-top-right-radius: $borderRadius;
    -moz-border-radius-bottomright: $borderRadius;
    -webkit-border-bottom-right-radius: $borderRadius;
    border-bottom-right-radius: $borderRadius;
}

body .p-fluid .p-inputgroup .p-button {
    width: auto;
}

body .p-fluid .p-inputgroup .p-button.p-button-icon-only {
    width: 2.357em;
}

body ::-webkit-input-placeholder {
    color: #666666;
}

body :-moz-placeholder {
    color: #666666;
}

body ::-moz-placeholder {
    color: #666666;
}

body :-ms-input-placeholder {
    color: #666666;
}

body .p-inputtext.ng-dirty.ng-invalid,
body p-dropdown.ng-dirty.ng-invalid>.p-dropdown,
body p-autocomplete.ng-dirty.ng-invalid>.p-autocomplete>.p-inputtext,
body p-calendar.ng-dirty.ng-invalid>.p-calendar>.p-inputtext,
body p-chips.ng-dirty.ng-invalid>.p-inputtext,
body p-inputmask.ng-dirty.ng-invalid>.p-inputtext,
body p-checkbox.ng-dirty.ng-invalid .p-checkbox-box,
body p-radiobutton.ng-dirty.ng-invalid .p-radiobutton-box,
body p-inputswitch.ng-dirty.ng-invalid .p-inputswitch,
body p-listbox.ng-dirty.ng-invalid .p-inputtext,
body p-multiselect.ng-dirty.ng-invalid>.p-multiselect,
body p-spinner.ng-dirty.ng-invalid>.p-inputtext,
body p-selectbutton.ng-dirty.ng-invalid .p-button,
body p-togglebutton.ng-dirty.ng-invalid .p-button {
    border: 1px solid #a80000;
}

body .p-button {
    .p-button-icon.pi.pi-plus{
        color: black;
    }
    .p-button-icon.pi.pi-minus{
        color: black;
    }
    margin: 0;
    color: var(--text-color-white);
    font-size: 14px;
    -moz-transition: background-color 0.2s, box-shadow 0.2s;
    -o-transition: background-color 0.2s, box-shadow 0.2s;
    -webkit-transition: background-color 0.2s, box-shadow 0.2s;
    transition: background-color 0.2s, box-shadow 0.2s;

}

.button-transparent-logo {
    span.p-button-label.p-clickable {
        overflow: hidden;
        max-width: 100px;
        text-overflow: ellipsis;
    }

    background-color: var(--button-text-color-secondary) !important;
}

.button-transparent {
    span.p-button-label.p-clickable {
        overflow: hidden;
        // max-width: 200px;
        text-overflow: ellipsis;
    }

    background-color: var(--button-text-color-secondary) !important;
}


body .p-button:enabled:hover {
    color: var(--text-color-white);
}

body .p-button:enabled:focus {
    @include button-element();
    color: var(--text-color-white);
    outline: 0 none;
    outline-offset: 0px;
    outline: 0;
}

body .p-button:enabled:active {
    background-color: var(--button-background-color-primary);
    color: var(--text-color-white);
    border-color: var(--border-color-muted);
}

body .p-button.p-button-label-only .p-button-label {
    padding: 0.429em 1em;
}

body .p-button.p-button-label-icon-left .p-button-label {
    padding: 0.429em 1em 0.429em 2em;
}

body .p-button.p-button-label-icon-right .p-button-label {
    padding: 0.429em 2em 0.429em 1em;
}

body .p-button.p-button-icon-only {
    width: 2.357em;
}


body .p-button.p-button-icon-only .p-button-label {
    padding: 0.429em;
    display: none;
}

body .p-button.p-button-raised {
    -webkit-box-shadow: 0 2px $borderRadius 0 rgba(0, 0, 0, 0.15);
    -moz-box-shadow: 0 2px $borderRadius 0 rgba(0, 0, 0, 0.15);
    box-shadow: 0 2px $borderRadius 0 rgba(0, 0, 0, 0.15);
}

body .p-button.p-button-rounded {
    -moz-border-radius: 15px;
    -webkit-border-radius: 15px;
    border-radius: 15px;
}

body .p-fluid .p-button-icon-only {
    width: 2.357em;
}

body .p-togglebutton {
    background-color: #dadada;
    border: 1px solid #dadada;
    color: var(--text-color-dark);
    -moz-transition: background-color 0.2s, box-shadow 0.2s;
    -o-transition: background-color 0.2s, box-shadow 0.2s;
    -webkit-transition: background-color 0.2s, box-shadow 0.2s;
    transition: background-color 0.2s, box-shadow 0.2s;
}

body .p-togglebutton .p-button-icon-left {
    color: #666666;
}

body .p-togglebutton:not(.p-disabled):not(.p-highlight):hover {
    background-color: #c8c8c8;
    border-color: #c8c8c8;
    color: var(--text-color-dark);
}

body .p-togglebutton:not(.p-disabled):not(.p-highlight):hover .p-button-icon-left {
    color: #212121;
}

body .p-togglebutton:not(.p-disabled):not(.p-highlight).p-focus {
    background-color: #c8c8c8;
    border-color: #c8c8c8;
    color: var(--text-color-dark);
    outline: 0 none;
}

body .p-togglebutton:not(.p-disabled):not(.p-highlight).p-focus .p-button-icon-left {
    color: #212121;
}

body .p-togglebutton.p-highlight {
    background-color: #007ad9;
    border-color: #007ad9;
    color: var(--text-color-white);
}

body .p-togglebutton.p-highlight .p-button-icon-left {
    color: var(--text-color-white);
}

body .p-togglebutton.p-highlight:hover {
    background-color: #116fbf;
    border-color: #116fbf;
    color: var(--text-color-white);
}

body .p-togglebutton.p-highlight:hover .p-button-icon-left {
    color: var(--text-color-white);
}

body .p-togglebutton.p-highlight.p-focus {
    background-color: #005b9f;
    border-color: #005b9f;
    color: var(--text-color-white);
}

body .p-togglebutton.p-highlight.p-focus .p-button-icon-left {
    color: var(--text-color-white);
}

body .p-selectbutton .p-button {
    background-color: #dadada;
    border: 1px solid #dadada;
    color: var(--text-color-dark);
    -moz-transition: background-color 0.2s, box-shadow 0.2s;
    -o-transition: background-color 0.2s, box-shadow 0.2s;
    -webkit-transition: background-color 0.2s, box-shadow 0.2s;
    transition: background-color 0.2s, box-shadow 0.2s;
}

body .p-selectbutton .p-button .p-button-icon-left {
    color: #666666;
}

body .p-selectbutton .p-button:not(.p-disabled):not(.p-highlight):hover {
    background-color: #c8c8c8;
    border-color: #c8c8c8;
    color: var(--text-color-dark);
}

body .p-selectbutton .p-button:not(.p-disabled):not(.p-highlight):hover .p-button-icon-left {
    color: #212121;
}

body .p-selectbutton .p-button:not(.p-disabled):not(.p-highlight).p-focus {
    background-color: #c8c8c8;
    border-color: #c8c8c8;
    color: var(--text-color-dark);
    outline: 0 none;
}

body .p-selectbutton .p-button:not(.p-disabled):not(.p-highlight).p-focus .p-button-icon-left {
    color: #212121;
}

body .p-selectbutton .p-button.p-highlight {
    background-color: #007ad9;
    border-color: #007ad9;
    color: var(--text-color-white);
}

body .p-selectbutton .p-button.p-highlight .p-button-icon-left {
    color: var(--text-color-white);
}

body .p-selectbutton .p-button.p-highlight:not(.p-disabled):hover {
    background-color: #116fbf;
    border-color: #116fbf;
    color: var(--text-color-white);
}

body .p-selectbutton .p-button.p-highlight:not(.p-disabled):hover .p-button-icon-left {
    color: var(--text-color-white);
}

body .p-selectbutton .p-button.p-highlight.p-focus {
    background-color: #005b9f;
    border-color: #005b9f;
    color: var(--text-color-white);
}

body .p-selectbutton .p-button.p-highlight.p-focus .p-button-icon-left {
    color: var(--text-color-white);
}

body .p-selectbutton .p-button:first-child {
    -moz-border-radius-topleft: $borderRadius;
    -webkit-border-top-left-radius: $borderRadius;
    border-top-left-radius: $borderRadius;
    -moz-border-radius-bottomleft: $borderRadius;
    -webkit-border-bottom-left-radius: $borderRadius;
    border-bottom-left-radius: $borderRadius;
}

body .p-selectbutton .p-button:last-child {
    -moz-border-radius-topright: $borderRadius;
    -webkit-border-top-right-radius: $borderRadius;
    border-top-right-radius: $borderRadius;
    -moz-border-radius-bottomright: $borderRadius;
    -webkit-border-bottom-right-radius: $borderRadius;
    border-bottom-right-radius: $borderRadius;
}

@media (max-width: 640px) {
    body .p-buttonset:not(.p-splitbutton) .p-button {
        margin-bottom: 1px;
        -moz-border-radius: $borderRadius;
        -webkit-border-radius: $borderRadius;
        border-radius: $borderRadius;
    }
}

body .p-splitbutton.p-buttonset .p-button {
    border: 1px solid transparent;
}

body .p-splitbutton.p-buttonset .p-menu {
    min-width: 14.2em;
}

body .p-button.p-state-default.p-button-secondary,
body .p-buttonset.p-button-secondary>.p-button.p-state-default {
    color: var(--text-color-dark);
    background-color: #f4f4f4;
    border: 1px solid #f4f4f4;
}

body .p-button.p-state-default.p-button-secondary:enabled:hover,
body .p-buttonset.p-button-secondary>.p-button.p-state-default:enabled:hover {
    background-color: #c8c8c8;
    color: var(--text-color-dark);
    border-color: #c8c8c8;
}

body .p-button.p-state-default.p-button-secondary:enabled:focus,
body .p-buttonset.p-button-secondary>.p-button.p-state-default:enabled:focus {
    -webkit-box-shadow: 0 0 0 0.2em #8dcdff;
    -moz-box-shadow: 0 0 0 0.2em #8dcdff;
    box-shadow: 0 0 0 0.2em #8dcdff;
}

body .p-button.p-state-default.p-button-secondary:enabled:active,
body .p-buttonset.p-button-secondary>.p-button.p-state-default:enabled:active {
    background-color: #a0a0a0;
    color: var(--text-color-dark);
    border-color: #a0a0a0;
}

body .p-button.p-state-default.p-button-info,
body .p-buttonset.p-button-info>.p-button.p-state-default {
    color: var(--text-color-white);
    background-color: #007ad9;
    border: 1px solid #007ad9;
}

body .p-button.p-state-default.p-button-info:enabled:hover,
body .p-buttonset.p-button-info>.p-button.p-state-default:enabled:hover {
    background-color: #116fbf;
    color: var(--text-color-white);
    border-color: #116fbf;
}

body .p-button.p-state-default.p-button-info:enabled:focus,
body .p-buttonset.p-button-info>.p-button.p-state-default:enabled:focus {
    -webkit-box-shadow: 0 0 0 0.2em #8dcdff;
    -moz-box-shadow: 0 0 0 0.2em #8dcdff;
    box-shadow: 0 0 0 0.2em #8dcdff;
}

body .p-button.p-state-default.p-button-info:enabled:active,
body .p-buttonset.p-button-info>.p-button.p-state-default:enabled:active {
    background-color: #005b9f;
    color: var(--text-color-white);
    border-color: #005b9f;
}

body .p-button.p-state-default.p-button-success,
body .p-buttonset.p-button-success>.p-button.p-state-default {
    color: var(--text-color-white);
    background-color: #34A835;
    border: 1px solid #34A835;
}

body .p-button.p-state-default.p-button-success:enabled:hover,
body .p-buttonset.p-button-success>.p-button.p-state-default:enabled:hover {
    background-color: #107D11;
    color: var(--text-color-white);
    border-color: #107D11;
}

body .p-button.p-state-default.p-button-success:enabled:focus,
body .p-buttonset.p-button-success>.p-button.p-state-default:enabled:focus {
    -webkit-box-shadow: 0 0 0 0.2em #aae5aa;
    -moz-box-shadow: 0 0 0 0.2em #aae5aa;
    box-shadow: 0 0 0 0.2em #aae5aa;
}

body .p-button.p-state-default.p-button-success:enabled:active,
body .p-buttonset.p-button-success>.p-button.p-state-default:enabled:active {
    background-color: #0C6B0D;
    color: var(--text-color-white);
    border-color: #0C6B0D;
}

body .p-button.p-state-default.p-button-warning,
body .p-buttonset.p-button-warning>.p-button.p-state-default {
    color: var(--text-color-dark);
    background-color: #ffba01;
    border: 1px solid #ffba01;
}

body .p-button.p-state-default.p-button-warning:enabled:hover,
body .p-buttonset.p-button-warning>.p-button.p-state-default:enabled:hover {
    background-color: #ED990B;
    color: var(--text-color-dark);
    border-color: #ED990B;
}

body .p-button.p-state-default.p-button-warning:enabled:focus,
body .p-buttonset.p-button-warning>.p-button.p-state-default:enabled:focus {
    -webkit-box-shadow: 0 0 0 0.2em #ffeab4;
    -moz-box-shadow: 0 0 0 0.2em #ffeab4;
    box-shadow: 0 0 0 0.2em #ffeab4;
}

body .p-button.p-state-default.p-button-warning:enabled:active,
body .p-buttonset.p-button-warning>.p-button.p-state-default:enabled:active {
    background-color: #D38B10;
    color: var(--text-color-dark);
    border-color: #D38B10;
}

body .p-button.p-state-default.p-button-danger,
body .p-buttonset.p-button-danger>.p-button.p-state-default {
    color: var(--text-color-white);
    background-color: #e91224;
    border: 1px solid #e91224;
}

body .p-button.p-state-default.p-button-danger:enabled:hover,
body .p-buttonset.p-button-danger>.p-button.p-state-default:enabled:hover {
    background-color: #c01120;
    color: var(--text-color-white);
    border-color: #c01120;
}

body .p-button.p-state-default.p-button-danger:enabled:focus,
body .p-buttonset.p-button-danger>.p-button.p-state-default:enabled:focus {
    -webkit-box-shadow: 0 0 0 0.2em #f9b4ba;
    -moz-box-shadow: 0 0 0 0.2em #f9b4ba;
    box-shadow: 0 0 0 0.2em #f9b4ba;
}

body .p-button.p-state-default.p-button-danger:enabled:active,
body .p-buttonset.p-button-danger>.p-button.p-state-default:enabled:active {
    background-color: #a90000;
    color: var(--text-color-white);
    border-color: #a90000;
}

body .p-component-content p {
    line-height: 1.5;
    margin: 0;
}

body .p-panel {
    padding: 0;
    border: 0 none;
}

body .p-panel .p-panel-titlebar {
    border: 1px solid #c8c8c8;
    padding: 0.571em 1em;
    background-color: #f4f4f4;
    color: var(--text-color-dark);
    -moz-border-radius-topleft: $borderRadius;
    -webkit-border-top-left-radius: $borderRadius;
    border-top-left-radius: $borderRadius;
    -moz-border-radius-topright: $borderRadius;
    -webkit-border-top-right-radius: $borderRadius;
    border-top-right-radius: $borderRadius;
    -moz-border-radius-bottomleft: 0;
    -webkit-border-bottom-left-radius: 0;
    border-bottom-left-radius: 0;
    -moz-border-radius-bottomright: 0;
    -webkit-border-bottom-right-radius: 0;
    border-bottom-right-radius: 0;
}

body .p-panel .p-panel-titlebar .p-panel-title {
    vertical-align: middle;
    font-weight: 700;
}

body .p-panel .p-panel-titlebar .p-panel-titlebar-icon {
    margin: 0;
    position: relative;
    font-size: 14px;
    color: #848484;
    border: 1px solid transparent;
    -moz-transition: color 0.2s;
    -o-transition: color 0.2s;
    -webkit-transition: color 0.2s;
    transition: color 0.2s;
}

body .p-panel .p-panel-titlebar .p-panel-titlebar-icon:hover {
    color: var(--text-color-dark);
}

body .p-panel .p-panel-content {
    padding: 0.571em 1em;
    border: 1px solid #c8c8c8;
    background-color: var(--text-color-white);
    color: var(--text-color-dark);
    padding: 0.571em 1em;
    border-top: 0 none;
}

body .p-panel .p-panel-footer {
    padding: 0.571em 1em;
    border: 1px solid #c8c8c8;
    background-color: var(--text-color-white);
    color: var(--text-color-dark);
    border-top: 0 none;
    margin: 0;
}

body .p-fieldset {
    border: 1px solid #c8c8c8;
    background-color: var(--background-color-white);
    color: var(--text-color-dark);
}

body .p-fieldset .p-fieldset-legend a {
    padding: 0.571em 1em;
    border: 1px solid #c8c8c8;
    color: var(--text-color-dark);
    background-color: #f4f4f4;
    -moz-border-radius: $borderRadius;
    -webkit-border-radius: $borderRadius;
    border-radius: $borderRadius;
    -moz-transition: background-color 0.2s;
    -o-transition: background-color 0.2s;
    -webkit-transition: background-color 0.2s;
    transition: background-color 0.2s;
}

body .p-fieldset .p-fieldset-legend a .p-fieldset-toggler {
    float: none;
    display: inline-block;
    vertical-align: middle;
    margin-right: 0.5em;
    color: #848484;
}

body .p-fieldset .p-fieldset-legend a .p-fieldset-legend-text {
    padding: 0;
}

body .p-fieldset.p-fieldset-toggleable .p-fieldset-legend a:hover {
    background-color: #dbdbdb;
    border: 1px solid #dbdbdb;
    color: var(--text-color-dark);
}

body .p-fieldset.p-fieldset-toggleable .p-fieldset-legend a:hover .p-fieldset-toggler {
    color: var(--text-color-dark);
}

body .p-fieldset .p-fieldset-content {
    padding: 0;
}

body .p-accordion .p-accordion-header {
    margin-top: 0px;
    border: none;
    outline: 0;
}

body .p-accordion .p-accordion-header .p-accordion-header-link {
    padding: 0.571em 1em;
    // border: 1px solid #c8c8c8;
    //accordion
    border: none;
    color: var(--text-color-dark);
    // background-color: #f4f4f4;
    //accordion
    background-color: white;
    color: var(--text-color-dark);
    font-weight: 700;
    -moz-transition: background-color 0.2s;
    -o-transition: background-color 0.2s;
    -webkit-transition: background-color 0.2s;
    transition: background-color 0.2s;
    outline: 0;
    display: initial;
    position: initial;
}

body .p-accordion .p-accordion-header .p-accordion-header-link .p-accordion-toggle-icon {
    color: black;
    //accordion
    display: inline-flex;
}

body .p-accordion .p-accordion-header:not(.p-highlight):not(.p-disabled):hover .p-accordion-header-link {
    // background-color: #dbdbdb;
    // border: 1px solid #dbdbdb;

    //accordion
    background-color: white;
    border: white;
    color: var(--text-color-dark);
    text-decoration: none;
}

body .p-accordion .p-accordion-header:not(.p-highlight):not(.p-disabled):hover .p-accordion-header-link .p-accordion-toggle-icon {
    color: var(--text-color-dark);
}

body .p-accordion .p-accordion-header:not(.p-disabled).p-highlight .p-accordion-header-link {
    // background-color: #007ad9;
    // border: 1px solid #007ad9;
    // color: #ffffff;

    //accordion
    border-top: 0;
    border-right: 0;
    border-bottom: 2px solid gray;
    border-left: 0;
    background-color: white;
    color: var(--text-color-dark);
    text-decoration: none;
    outline: 0;
}

body .p-accordion .p-accordion-header:not(.p-disabled).p-highlight .p-accordion-header-link .p-accordion-toggle-icon {
    color: black;
}

body .p-accordion .p-accordion-header:not(.p-disabled).p-highlight:hover .p-accordion-header-link {
    // border: 1px solid #005b9f;
    // background-color: #005b9f;
    // color: #ffffff;

    //accordion
    border-top: 0;
    border-right: 0;
    border-bottom: 4px solid gray;
    border-left: 0;
    background-color: white;
    color: var(--text-color-dark);
    text-decoration: none;
}

body .p-accordion .p-accordion-header:not(.p-disabled).p-highlight:hover .p-accordion-header-link .p-accordion-toggle-icon {
    // color: #ffffff;

    //accordion
    color: var(--text-color-dark);
}

body .p-accordion .p-accordion-content {
    padding: 0.571em 1em;
    //border: 1px solid #c8c8c8;
    background-color: var(--background-color-white);
    color: var(--text-color-dark);
    padding: 0.571em 1em;
}

body .p-tabview.p-tabview-top,
body .p-tabview.p-tabview-bottom,
body .p-tabview.p-tabview-left,
body .p-tabview.p-tabview-right {
    border: 0 none;
}

body .p-tabview.p-tabview-top .p-tabview-nav,
body .p-tabview.p-tabview-bottom .p-tabview-nav,
body .p-tabview.p-tabview-left .p-tabview-nav,
body .p-tabview.p-tabview-right .p-tabview-nav {
    padding: 0;
    background: transparent;
    border: 0 none;
}

body .p-tabview.p-tabview-top .p-tabview-nav li,
body .p-tabview.p-tabview-bottom .p-tabview-nav li,
body .p-tabview.p-tabview-left .p-tabview-nav li,
body .p-tabview.p-tabview-right .p-tabview-nav li {
    border: 1px solid #c8c8c8;
    background-color: #f4f4f4;
    -moz-transition: background-color 0.2s;
    -o-transition: background-color 0.2s;
    -webkit-transition: background-color 0.2s;
    transition: background-color 0.2s;
}

body .p-tabview.p-tabview-top .p-tabview-nav li a,
body .p-tabview.p-tabview-bottom .p-tabview-nav li a,
body .p-tabview.p-tabview-left .p-tabview-nav li a,
body .p-tabview.p-tabview-right .p-tabview-nav li a {
    float: none;
    display: inline-block;
    color: var(--text-color-dark);
    padding: 0.571em 1em;
    font-weight: 700;
}

body .p-tabview.p-tabview-top .p-tabview-nav li a .p-tabview-left-icon,
body .p-tabview.p-tabview-bottom .p-tabview-nav li a .p-tabview-left-icon,
body .p-tabview.p-tabview-left .p-tabview-nav li a .p-tabview-left-icon,
body .p-tabview.p-tabview-right .p-tabview-nav li a .p-tabview-left-icon {
    margin-right: 0.5em;
}

body .p-tabview.p-tabview-top .p-tabview-nav li a .p-tabview-right-icon,
body .p-tabview.p-tabview-bottom .p-tabview-nav li a .p-tabview-right-icon,
body .p-tabview.p-tabview-left .p-tabview-nav li a .p-tabview-right-icon,
body .p-tabview.p-tabview-right .p-tabview-nav li a .p-tabview-right-icon {
    margin-left: 0.5em;
}

body .p-tabview.p-tabview-top .p-tabview-nav li .p-tabview-close,
body .p-tabview.p-tabview-bottom .p-tabview-nav li .p-tabview-close,
body .p-tabview.p-tabview-left .p-tabview-nav li .p-tabview-close,
body .p-tabview.p-tabview-right .p-tabview-nav li .p-tabview-close {
    color: #848484;
    margin: 0 0.5em 0 0;
    vertical-align: middle;
}

body .p-tabview.p-tabview-top .p-tabview-nav li:not(.p-highlight):not(.p-disabled):hover,
body .p-tabview.p-tabview-bottom .p-tabview-nav li:not(.p-highlight):not(.p-disabled):hover,
body .p-tabview.p-tabview-left .p-tabview-nav li:not(.p-highlight):not(.p-disabled):hover,
body .p-tabview.p-tabview-right .p-tabview-nav li:not(.p-highlight):not(.p-disabled):hover {
    background-color: #dbdbdb;
    border: 1px solid #dbdbdb;
}

body .p-tabview.p-tabview-top .p-tabview-nav li:not(.p-highlight):not(.p-disabled):hover a,
body .p-tabview.p-tabview-bottom .p-tabview-nav li:not(.p-highlight):not(.p-disabled):hover a,
body .p-tabview.p-tabview-left .p-tabview-nav li:not(.p-highlight):not(.p-disabled):hover a,
body .p-tabview.p-tabview-right .p-tabview-nav li:not(.p-highlight):not(.p-disabled):hover a {
    color: var(--text-color-dark);
}

body .p-tabview.p-tabview-top .p-tabview-nav li:not(.p-highlight):not(.p-disabled):hover .p-tabview-close,
body .p-tabview.p-tabview-bottom .p-tabview-nav li:not(.p-highlight):not(.p-disabled):hover .p-tabview-close,
body .p-tabview.p-tabview-left .p-tabview-nav li:not(.p-highlight):not(.p-disabled):hover .p-tabview-close,
body .p-tabview.p-tabview-right .p-tabview-nav li:not(.p-highlight):not(.p-disabled):hover .p-tabview-close {
    color: var(--text-color-dark);
}

body .p-tabview.p-tabview-top .p-tabview-nav li.p-highlight,
body .p-tabview.p-tabview-bottom .p-tabview-nav li.p-highlight,
body .p-tabview.p-tabview-left .p-tabview-nav li.p-highlight,
body .p-tabview.p-tabview-right .p-tabview-nav li.p-highlight {
    @include active-element();
    outline: 0;
}

body .p-tabview.p-tabview-top .p-tabview-nav li.p-highlight a,
body .p-tabview.p-tabview-bottom .p-tabview-nav li.p-highlight a,
body .p-tabview.p-tabview-left .p-tabview-nav li.p-highlight a,
body .p-tabview.p-tabview-right .p-tabview-nav li.p-highlight a {
    color: var(--text-color-dark);
}

body .p-tabview.p-tabview-top .p-tabview-nav li.p-highlight .p-tabview-close,
body .p-tabview.p-tabview-bottom .p-tabview-nav li.p-highlight .p-tabview-close,
body .p-tabview.p-tabview-left .p-tabview-nav li.p-highlight .p-tabview-close,
body .p-tabview.p-tabview-right .p-tabview-nav li.p-highlight .p-tabview-close {
    color: var(--text-color-dark);
}

body .p-tabview.p-tabview-top .p-tabview-nav li.p-highlight:hover,
body .p-tabview.p-tabview-bottom .p-tabview-nav li.p-highlight:hover,
body .p-tabview.p-tabview-left .p-tabview-nav li.p-highlight:hover,
body .p-tabview.p-tabview-right .p-tabview-nav li.p-highlight:hover {
    @include active-element();
}

body .p-tabview.p-tabview-top .p-tabview-nav li.p-highlight:hover a,
body .p-tabview.p-tabview-bottom .p-tabview-nav li.p-highlight:hover a,
body .p-tabview.p-tabview-left .p-tabview-nav li.p-highlight:hover a,
body .p-tabview.p-tabview-right .p-tabview-nav li.p-highlight:hover a {
    color: var(--text-color-dark);
}

body .p-tabview.p-tabview-top .p-tabview-nav li.p-highlight:hover a .p-tabview-left-icon,
body .p-tabview.p-tabview-top .p-tabview-nav li.p-highlight:hover a .p-tabview-right-icon,
body .p-tabview.p-tabview-bottom .p-tabview-nav li.p-highlight:hover a .p-tabview-left-icon,
body .p-tabview.p-tabview-bottom .p-tabview-nav li.p-highlight:hover a .p-tabview-right-icon,
body .p-tabview.p-tabview-left .p-tabview-nav li.p-highlight:hover a .p-tabview-left-icon,
body .p-tabview.p-tabview-left .p-tabview-nav li.p-highlight:hover a .p-tabview-right-icon,
body .p-tabview.p-tabview-right .p-tabview-nav li.p-highlight:hover a .p-tabview-left-icon,
body .p-tabview.p-tabview-right .p-tabview-nav li.p-highlight:hover a .p-tabview-right-icon {
    color: var(--text-color-dark);
}

body .p-tabview.p-tabview-top .p-tabview-nav li.p-tabview-selected a,
body .p-tabview.p-tabview-bottom .p-tabview-nav li.p-tabview-selected a,
body .p-tabview.p-tabview-left .p-tabview-nav li.p-tabview-selected a,
body .p-tabview.p-tabview-right .p-tabview-nav li.p-tabview-selected a {
    cursor: pointer;
}

body .p-tabview .p-tabview-nav{
    flex-wrap: initial;

    li .p-tabview-nav-link{
        border: 0;
    }
}

body .p-tabview.p-tabview-top .p-tabview-nav {
    margin-bottom: -1px;
}

body .p-tabview.p-tabview-top .p-tabview-nav li {
    margin-right: 2px;
}

body .p-tabview.p-tabview-bottom .p-tabview-nav {
    margin-top: -1px;
}

body .p-tabview.p-tabview-bottom .p-tabview-nav li {
    margin-right: 2px;
}

body .p-tabview.p-tabview-left .p-tabview-nav {
    margin-right: -px;
}

body .p-tabview.p-tabview-left .p-tabview-nav li {
    margin-bottom: 2px;
}

body .p-tabview.p-tabview-right .p-tabview-nav {
    margin-right: -1px;
}

body .p-tabview.p-tabview-right .p-tabview-nav li {
    margin-bottom: 2px;
}

body .p-tabview .p-tabview-panels {
    background-color: var(--background-color-white);
    padding: 0.571em 1em;
    border: 1px solid #c8c8c8;
    color: var(--text-color-dark);
}

body .p-tabview .p-tabview-panels .p-tabview-panel {
    padding: 0;
}
body .p-tabview{
    a{
        border-color: transparent transparent var(--border-color-primary)!important;
        
        color: var(--text-color-dark)!important;
        outline: 0!important;
        justify-content: center;
        font-weight: 800!important;
    }
    
    li.p-highlight{
        a{
            background-color: var(--background-color-white)!important;
        }
    }
}

body .p-toolbar {
    background-color: #f4f4f4;
    border: 1px solid #c8c8c8;
    padding: 0.571em 1em;
}

body .p-toolbar button {
    vertical-align: middle;
}

body .p-toolbar .p-toolbar-separator {
    vertical-align: middle;
    color: #848484;
    margin: 0 0.5em;
}

body .p-card {
    background-color: var(--background-color-white);
    color: var(--text-color-dark);
    -webkit-box-shadow: 0 1px $borderRadius 0 rgba(0, 0, 0, 0.2), 0 1px 1px 0 rgba(0, 0, 0, 0.14), 0 2px 1px -1px rgba(0, 0, 0, 0.12);
    -moz-box-shadow: 0 1px $borderRadius 0 rgba(0, 0, 0, 0.2), 0 1px 1px 0 rgba(0, 0, 0, 0.14), 0 2px 1px -1px rgba(0, 0, 0, 0.12);
    box-shadow: 0 1px $borderRadius 0 rgba(0, 0, 0, 0.2), 0 1px 1px 0 rgba(0, 0, 0, 0.14), 0 2px 1px -1px rgba(0, 0, 0, 0.12);
}

body .p-paginator {
    background-color: #f4f4f4;
    border: 1px solid #c8c8c8;
    padding: 0;
}

body .p-paginator .p-paginator-first,
body .p-paginator .p-paginator-prev,
body .p-paginator .p-paginator-next,
body .p-paginator .p-paginator-last {
    color: #848484;
    height: 2.286em;
    min-width: 2.286em;
    border: 0 none;
    line-height: 2.286em;
    padding: 0;
    margin: 0;
    vertical-align: top;
    -moz-border-radius: 0;
    -webkit-border-radius: 0;
    border-radius: 0;
}

body .p-paginator .p-paginator-first:not(.p-disabled):not(.p-highlight):hover,
body .p-paginator .p-paginator-prev:not(.p-disabled):not(.p-highlight):hover,
body .p-paginator .p-paginator-next:not(.p-disabled):not(.p-highlight):hover,
body .p-paginator .p-paginator-last:not(.p-disabled):not(.p-highlight):hover {
    background-color: #e0e0e0;
    color: var(--text-color-dark);
}

body .p-paginator .p-paginator-current {
    color: #848484;
    height: 2.286em;
    min-width: 2.286em;
    line-height: 2.286em;
}

body .p-paginator .p-dropdown {
    border: 0 none;
}

body .p-paginator .p-dropdown .p-dropdown-trigger,
body .p-paginator .p-dropdown .p-dropdown-label {
    color: #848484;
}

body .p-paginator .p-dropdown:hover .p-dropdown-trigger,
body .p-paginator .p-dropdown:hover .p-dropdown-label {
    color: var(--text-color-dark);
}

body .p-paginator .p-paginator-first:before {
    position: relative;
    top: 1px;
}

body .p-paginator .p-paginator-prev:before {
    position: relative;
}

body .p-paginator .p-paginator-next:before {
    position: relative;
    top: 1px;
}

body .p-paginator .p-paginator-last:before {
    position: relative;
    top: 1px;
}

body .p-paginator .p-paginator-pages {
    vertical-align: top;
    display: inline-block;
    padding: 0;
}

body .p-paginator .p-paginator-pages .p-paginator-page {
    color: #848484;
    height: 2.286em;
    min-width: 2.286em;
    border: 0 none;
    line-height: 2.286em;
    padding: 0;
    margin: 0;
    vertical-align: top;
    -moz-border-radius: 0;
    -webkit-border-radius: 0;
    border-radius: 0;
}

body .p-paginator .p-paginator-pages .p-paginator-page.p-highlight {
    background-color: #007ad9;
    color: var(--text-color-white);
}

body .p-paginator .p-paginator-pages .p-paginator-page:not(.p-highlight):hover {
    background-color: #e0e0e0;
    color: var(--text-color-dark);
}

body .p-paginator .p-dropdown {
    margin-left: 0.5em;
    height: 2.286em;
    min-width: auto;
}

body .p-table .p-table-caption,
body .p-table .p-table-summary {
    background-color: #f4f4f4;
    color: var(--text-color-dark);
    border: 1px solid #c8c8c8;
    padding: 0.571em 1em;
    text-align: center;
}

body .p-table .p-table-caption {
    border-bottom: 0 none;
    font-weight: 700;
}

body .p-table .p-table-summary {
    border-top: 0 none;
    font-weight: 700;
}

body .p-table .p-table-thead>tr>th {
    padding: 0.671em 0.571em;
    border: 0px;
    border-bottom: lightgray solid 0.1px;
    font-size: 100%;
    font-weight: 500;
    color: #7d7373;
    background-color: var(--background-color-table-header);
}

body .p-table .p-table-tbody>tr>td {
    padding: 0.671em 0.671em;

}

body .p-table .p-table-tfoot>tr>td {
    padding: 0.671em 0.671em;
    border: 1px solid #c8c8c8;
    font-weight: 700;
    color: var(--text-color-dark);
    background-color: var(--background-color-white);
}

body .p-table .p-sortable-column .p-sortable-column-icon {
    color: #848484;
}

body .p-table .p-sortable-column:not(.p-highlight):hover {
    background-color: #e0e0e0;
    color: var(--text-color-dark);
}

body .p-table .p-sortable-column:not(.p-highlight):hover .p-sortable-column-icon {
    color: var(--text-color-dark);
}

body .p-table .p-sortable-column.p-highlight {
    // background-color: var(--background-color-muted);
    background-color: var(--color-grey-lighten-7);
    color: var(--text-color-muted);
}

body .p-table .p-sortable-column.p-highlight .p-sortable-column-icon {
    // color: var(--text-color-white);
    color: var(--sortable-icon);
}

body .p-table .p-editable-column input {
    font-size: 14px;
    font-family: $font-regular
}

body .p-table .p-editable-column input:focus {
    outline: 1px solid #007ad9;
    outline-offset: 2px;
}

body .p-table .p-table-tbody>tr {
    background-color: var(--background-color-white);
    color: var(--text-color-dark);
}

body .p-table .p-table-tbody>tr>td {
    background-color: inherit;
    border: 0px;
    border-bottom: lightgray solid 0.1px;
    font-size: 110%;
}

body .p-table .p-table-tbody>tr.p-highlight {
    background-color: #007ad9;
    color: var(--text-color-white);
}

body .p-table .p-table-tbody>tr.p-highlight a {
    color: var(--text-color-white);
}

body .p-table .p-table-tbody>tr.p-contextmenu-selected {
    background-color: #007ad9;
    color: var(--text-color-white);
}

body .p-table .p-table-tbody>tr.p-table-dragpoint-bottom>td {
    -webkit-box-shadow: inset 0 -2px 0 0 #007ad9;
    -moz-box-shadow: inset 0 -2px 0 0 #007ad9;
    box-shadow: inset 0 -2px 0 0 #007ad9;
}

body .p-table .p-table-tbody>tr:nth-child(even).p-highlight {
    background-color: #007ad9;
    color: var(--text-color-white);
}

body .p-table .p-table-tbody>tr:nth-child(even).p-highlight a {
    color: var(--text-color-white);
}

body .p-table .p-table-tbody>tr:nth-child(even).p-contextmenu-selected {
    background-color: #007ad9;
    color: var(--text-color-white);
}

body .p-table.p-table-hoverable-rows .p-table-tbody>tr.p-selectable-row:not(.p-highlight):not(.p-contextmenu-selected):hover {
    cursor: pointer;
    background-color: #eaeaea;
    color: var(--text-color-dark);
}

body .p-table .p-column-resizer-helper {
    background-color: #007ad9;
}

body .p-table .p-table-tbody > tr:nth-child(even) {
    background-color: #f9f9f9;
}

@media screen and (max-width: 40em) {
    body .p-table.p-table-responsive .p-paginator-top {
        border-bottom: 1px solid #c8c8c8;
    }

    body .p-table.p-table-responsive .p-paginator-bottom {
        border-top: 1px solid #c8c8c8;
    }

    body .p-table.p-table-responsive .p-table-tbody>tr>td {
        border: 0 none;
    }
}

body .p-datagrid .p-datagrid-header {
    background-color: #f4f4f4;
    color: var(--text-color-dark);
    border: 1px solid #c8c8c8;
    padding: 0.571em 1em;
    font-weight: 700;
    border-bottom: 0 none;
}

body .p-datagrid .p-datagrid-content {
    padding: 0.571em 1em;
    border: 1px solid #c8c8c8;
    background-color: var(--background-color-white);
    color: var(--text-color-dark);
    padding: 0.571em 1em;
}

body .p-datagrid .p-datagrid-footer {
    background-color: var(--background-color-white);
    color: var(--text-color-dark);
    border: 1px solid #c8c8c8;
    padding: 0.571em 1em;
    border-top: 0 none;
}

body .p-datalist .p-datalist-header {
    background-color: #f4f4f4;
    color: var(--text-color-dark);
    border: 1px solid #c8c8c8;
    padding: 0.571em 1em;
    font-weight: 700;
    border-bottom: 0 none;
}

body .p-datalist .p-datalist-content {
    padding: 0.571em 1em;
    border: 1px solid #c8c8c8;
    background-color: var(--background-color-white);
    color: var(--text-color-dark);
    padding: 0.571em 1em;
}

body .p-datalist .p-datalist-footer {
    background-color: var(--background-color-white);
    color: var(--text-color-dark);
    border: 1px solid #c8c8c8;
    padding: 0.571em 1em;
    border-top: 0 none;
}

body .p-datascroller .p-datascroller-header {
    background-color: #f4f4f4;
    color: var(--text-color-dark);
    border: 1px solid #c8c8c8;
    padding: 0.571em 1em;
    font-weight: 700;
    border-bottom: 0 none;
}

body .p-datascroller .p-datascroller-content {
    padding: 0.571em 1em;
    border: 1px solid #c8c8c8;
    background-color: var(--background-color-white);
    color: var(--text-color-dark);
    padding: 0.571em 1em;
}

body .p-datascroller .p-datascroller-footer {
    background-color: var(--background-color-white);
    color: var(--text-color-dark);
    border: 1px solid #c8c8c8;
    padding: 0.571em 1em;
    border-top: 0 none;
}

body .p-virtualscroller .p-virtualscroller-header {
    background-color: #f4f4f4;
    color: var(--text-color-dark);
    border: 1px solid #c8c8c8;
    padding: 0.571em 1em;
    font-weight: 700;
    border-bottom: 0 none;
}

body .p-virtualscroller .p-virtualscroller-content {
    border: 1px solid #c8c8c8;
    background-color: var(--background-color-white);
    color: var(--text-color-dark);
}

body .p-virtualscroller .p-virtualscroller-content .p-virtualscroller-list li {
    border-bottom: 1px solid #c8c8c8;
}

body .p-virtualscroller .p-virtualscroller-footer {
    background-color: var(--background-color-white);
    color: var(--text-color-dark);
    border: 1px solid #c8c8c8;
    padding: 0.571em 1em;
    border-top: 0 none;
}

body .p-dataview .p-dataview-header {
    background-color: #f4f4f4;
    color: var(--text-color-dark);
    border: 1px solid #c8c8c8;
    padding: 0.571em 1em;
    font-weight: 700;
    border-bottom: 0 none;
}

body .p-dataview .p-dataview-content {
    padding: 0.571em 1em;
    border: 1px solid #c8c8c8;
    background-color: var(--background-color-white);
    color: var(--text-color-dark);
    padding: 0.571em 1em;
}

body .p-dataview .p-dataview-footer {
    background-color: var(--background-color-white);
    color: var(--text-color-dark);
    border: 1px solid #c8c8c8;
    padding: 0.571em 1em;
    border-top: 0 none;
}

body .fc th {
    background-color: #f4f4f4;
    border: 1px solid #c8c8c8;
    color: var(--text-color-dark);
    padding: 0.571em 1em;
}

body .fc td.p-component-content {
    background-color: var(--background-color-white);
    border: 1px solid #c8c8c8;
    color: var(--text-color-dark);
}

body .fc td.fc-head-container {
    border: 1px solid #c8c8c8;
}

body .fc .fc-row {
    border-right: 1px solid #c8c8c8;
}

body .fc .fc-event {
    background-color: #116fbf;
    border: 1px solid #116fbf;
    color: var(--text-color-white);
}

body .fc .fc-toolbar .fc-button {
    color: var(--text-color-white);
    background-color: #007ad9;
    border: 1px solid #007ad9;
    font-size: 14px;
    -moz-transition: background-color 0.2s, box-shadow 0.2s;
    -o-transition: background-color 0.2s, box-shadow 0.2s;
    -webkit-transition: background-color 0.2s, box-shadow 0.2s;
    transition: background-color 0.2s, box-shadow 0.2s;
    -moz-border-radius: $borderRadius;
    -webkit-border-radius: $borderRadius;
    border-radius: $borderRadius;
}

body .fc .fc-toolbar .fc-button:enabled:hover {
    background-color: #116fbf;
    color: var(--text-color-white);
    border-color: #116fbf;
}

body .fc .fc-toolbar .fc-button .fc-icon-chevron-left {
    font-family: "PrimeIcons" !important;
    text-indent: 0;
}

body .fc .fc-toolbar .fc-button .fc-icon-chevron-left:before {
    content: "";
}

body .fc .fc-toolbar .fc-button .fc-icon-chevron-right {
    font-family: "PrimeIcons" !important;
    text-indent: 0;
}

body .fc .fc-toolbar .fc-button .fc-icon-chevron-right:before {
    content: "";
}

body .fc .fc-toolbar .fc-button:focus {
    outline: 0 none;
    outline-offset: 0px;
    -webkit-box-shadow: 0 0 0 0.2em #8dcdff;
    -moz-box-shadow: 0 0 0 0.2em #8dcdff;
    box-shadow: 0 0 0 0.2em #8dcdff;
}

body .fc .fc-toolbar .fc-button-group .fc-button {
    -moz-border-radius: 0;
    -webkit-border-radius: 0;
    border-radius: 0;
}

body .fc .fc-toolbar .fc-button-group .fc-button:first-child {
    -moz-border-radius-topleft: $borderRadius;
    -webkit-border-top-left-radius: $borderRadius;
    border-top-left-radius: $borderRadius;
    -moz-border-radius-bottomleft: $borderRadius;
    -webkit-border-bottom-left-radius: $borderRadius;
    border-bottom-left-radius: $borderRadius;
}

body .fc .fc-toolbar .fc-button-group .fc-button:last-child {
    -moz-border-radius-topright: $borderRadius;
    -webkit-border-top-right-radius: $borderRadius;
    border-top-right-radius: $borderRadius;
    -moz-border-radius-bottomright: $borderRadius;
    -webkit-border-bottom-right-radius: $borderRadius;
    border-bottom-right-radius: $borderRadius;
}

body .fc .fc-divider {
    background-color: #f4f4f4;
    border: 1px solid #c8c8c8;
}

body .p-fluid .fc .fc-toolbar .fc-button {
    width: auto;
}

body .p-picklist .p-picklist-buttons button {
    font-size: 16px;
}

body .p-picklist button[icon="pi pi-angle-double-left"] {
    display: none !important;
}

body .p-picklist button[icon='pi pi-angle-double-right'] {
    display: none !important;
}

body .p-picklist button[icon="pi pi-angle-left"] {
    height: 1.5em !important;
}

body .p-picklist button[icon='pi pi-angle-right'] {
    height: 1.5em !important;
}

body .p-picklist .p-picklist-caption {
    background-color: #f4f4f4;
    color: var(--text-color-dark);
    border: 1px solid #c8c8c8;
    padding: 0.571em 1em;
    font-weight: 700;
    border-bottom: 0 none;
    -moz-border-radius-topleft: $borderRadius;
    -webkit-border-top-left-radius: $borderRadius;
    border-top-left-radius: $borderRadius;
    -moz-border-radius-topright: $borderRadius;
    -webkit-border-top-right-radius: $borderRadius;
    border-top-right-radius: $borderRadius;
}

body .p-picklist .p-picklist-filter-container {
    padding: 0.429em 0.857em 0.429em 0.857em;
    border: 1px solid #c8c8c8;
    color: var(--text-color-dark);
    background-color: var(--background-color-white);
    margin: 0;
    border-bottom: 0 none;
}

body .p-picklist .p-picklist-filter-container input.p-picklist-filter {
    width: 100%;
    padding-right: 2em;
    text-indent: 0;
}

body .p-picklist .p-picklist-filter-container .p-picklist-filter-icon {
    top: 50%;
    margin-top: -0.5em;
    left: auto;
    right: 1.357em;
    color: #007ad9;
}

body .p-picklist .p-picklist-buttons {
    padding: 0.571em 1em;
}

body .p-picklist .p-picklist-list {
    border: 1px solid #c8c8c8;
    background-color: var(--background-color-white);
    color: var(--text-color-dark);
    padding: 0;
    width: 100% !important;
}

body .p-picklist .p-picklist-list .p-picklist-item {
    padding: 0.429em 0.857em;
    margin: 0;
    border: 0 none;
    color: var(--text-color-dark);
    background-color: transparent;
}

body .p-picklist .p-picklist-list .p-picklist-item:not(.p-highlight):hover {
    background-color: #eaeaea;
    color: var(--text-color-dark);
}

body .p-picklist .p-picklist-list .p-picklist-item.p-highlight {
    background-color: #007ad9;
    color: var(--text-color-white);
}

body .p-picklist .p-picklist-list .p-picklist-droppoint-highlight {
    background-color: #007ad9;
}

@media (max-width: 40em) {
    body .p-picklist.p-picklist-responsive .p-picklist-buttons {
        padding: 0.571em 1em;
    }
}

body .p-orderlist .p-orderlist-controls {
    padding: 0.571em 1em;
}

body .p-orderlist .p-orderlist-controls button {
    font-size: 16px;
}

body .p-orderlist .p-orderlist-caption {
    background-color: #f4f4f4;
    color: var(--text-color-dark);
    border: 1px solid #c8c8c8;
    padding: 0.571em 1em;
    font-weight: 700;
    border-bottom: 0 none;
    -moz-border-radius-topleft: $borderRadius;
    -webkit-border-top-left-radius: $borderRadius;
    border-top-left-radius: $borderRadius;
    -moz-border-radius-topright: $borderRadius;
    -webkit-border-top-right-radius: $borderRadius;
    border-top-right-radius: $borderRadius;
}

body .p-orderlist .p-orderlist-filter-container {
    padding: 0.429em 0.857em 0.429em 0.857em;
    border: 1px solid #c8c8c8;
    color: var(--text-color-dark);
    background-color: var(--background-color-white);
    margin: 0;
    border-bottom: 0 none;
}

body .p-orderlist .p-orderlist-filter-container input.p-inputtext {
    width: 100%;
    padding-right: 2em;
    text-indent: 0;
}

body .p-orderlist .p-orderlist-filter-container .p-orderlist-filter-icon {
    top: 50%;
    margin-top: -0.5em;
    left: auto;
    right: 1.357em;
    color: #007ad9;
}

body .p-orderlist .p-orderlist-list {
    border: 1px solid #c8c8c8;
    background-color: var(--background-color-white);
    color: var(--text-color-dark);
    padding: 0;
}

body .p-orderlist .p-orderlist-list .p-orderlist-item {
    padding: 0.429em 0.857em;
    margin: 0;
    border: 0 none;
    color: var(--text-color-dark);
    background-color: transparent;
}

body .p-orderlist .p-orderlist-list .p-orderlist-item:not(.p-highlight):hover {
    background-color: #eaeaea;
    color: var(--text-color-dark);
}

body .p-orderlist .p-orderlist-list .p-orderlist-item.p-highlight {
    background-color: #007ad9;
    color: var(--text-color-white);
}

body .p-orderlist .p-orderlist-list .p-orderlist-droppoint-highlight {
    background-color: #007ad9;
}

body .p-tree {
    border: 1px solid #c8c8c8;
    background-color: var(--background-color-white);
    color: var(--text-color-dark);
    padding: 0.571em 1em;
}

body .p-tree .p-tree-container {
    padding: 0.286em;
    margin: 0;
}

body .p-tree .p-tree-container .p-treenode {
    padding: 0.143em 0;
}

body .p-tree .p-tree-container .p-treenode .p-treenode-content {
    padding: 0;
    border: 1px solid transparent;
}

body .p-tree .p-tree-container .p-treenode .p-treenode-content .p-tree-toggler {
    vertical-align: middle;
    display: inline-block;
    float: none;
    margin: 0 0.143em 0 0;
    color: #848484;
}

body .p-tree .p-tree-container .p-treenode .p-treenode-content .p-treenode-icon {
    vertical-align: middle;
    display: inline-block;
    margin: 0 0.143em 0 0;
    color: #848484;
}

body .p-tree .p-tree-container .p-treenode .p-treenode-content .p-treenode-label {
    margin: 0;
    vertical-align: middle;
    display: inline-block;
    padding: 0.286em;
}

body .p-tree .p-tree-container .p-treenode .p-treenode-content .p-treenode-label.p-highlight {
    @include text-red();
}

body .p-tree .p-tree-container .p-treenode .p-treenode-content .p-checkbox {
    margin: 0 0.5em 0 0;
}

body .p-tree .p-tree-container .p-treenode .p-treenode-content .p-checkbox .p-checkbox-icon {
    margin: 1px 0 0 0;
}

body .p-tree .p-tree-container .p-treenode .p-treenode-content.p-treenode-selectable .p-treenode-label:not(.p-highlight):hover {
    background-color: #eaeaea;
    color: var(--text-color-dark);
}

body .p-tree .p-tree-container .p-treenode .p-treenode-content>span {
    line-height: inherit;
}

body .p-tree .p-tree-container .p-treenode .p-treenode-content.p-treenode-dragover {
    background-color: #eaeaea;
    color: var(--text-color-dark);
}

body .p-tree .p-tree-container .p-treenode-droppoint.p-treenode-droppoint-active {
    background-color: #007ad9;
}

body .p-tree.p-tree-horizontal {
    padding-left: 0;
    padding-right: 0;
}

body .p-tree.p-tree-horizontal .p-treenode .p-treenode-content {
    border: 1px solid #c8c8c8;
    background-color: var(--background-color-white);
    color: var(--text-color-dark);
    padding: 0.571em 1em;
}

body .p-tree.p-tree-horizontal .p-treenode .p-treenode-content.p-highlight {
    background-color: #007ad9;
    color: var(--text-color-white);
}

body .p-tree.p-tree-horizontal .p-treenode .p-treenode-content .p-checkbox .p-icon {
    color: #007ad9;
}

body .p-tree.p-tree-horizontal .p-treenode .p-treenode-content .p-treenode-label:not(.p-highlight):hover {
    background-color: inherit;
    color: inherit;
}

body .p-tree.p-tree-horizontal .p-treenode .p-treenode-content.p-treenode-selectable:not(.p-highlight):hover {
    background-color: #eaeaea;
    color: var(--text-color-dark);
}

body .p-tree .p-tree-filter-container .p-tree-filter {
    width: 100%;
    padding-right: 2em;
}

body .p-tree .p-tree-filter-container .p-tree-filter-icon {
    top: 50%;
    left: auto;
    margin-top: -0.5em;
    right: 0.8em;
    color: #007ad9;
}

body .p-organizationchart .p-organizationchart-node-content.p-organizationchart-selectable-node:not(.p-highlight):hover {
    background-color: #eaeaea;
    color: var(--text-color-dark);
}

body .p-organizationchart .p-organizationchart-node-content.p-highlight {
    background-color: #007ad9;
    color: var(--text-color-white);
}

body .p-organizationchart .p-organizationchart-node-content.p-highlight .p-node-toggler i {
    color: #00325a;
}

body .p-organizationchart .p-organizationchart-line-down {
    background-color: #c8c8c8;
}

body .p-organizationchart .p-organizationchart-line-left {
    border-right: 1px solid #c8c8c8;
    border-color: #c8c8c8;
}

body .p-organizationchart .p-organizationchart-line-top {
    border-top: 1px solid #c8c8c8;
    border-color: #c8c8c8;
}

body .p-organizationchart .p-organizationchart-node-content {
    border: 1px solid #c8c8c8;
    background-color: var(--background-color-white);
    color: var(--text-color-dark);
    padding: 0.571em 1em;
}

body .p-organizationchart .p-organizationchart-node-content .p-node-toggler {
    bottom: -0.7em;
    margin-left: -0.46em;
    color: #848484;
}

body .p-carousel {
    padding: 0;
}

body .p-carousel .p-carousel-header {
    background-color: #f4f4f4;
    color: var(--text-color-dark);
    padding: 0.571em 1em;
    border: 1px solid #c8c8c8;
    margin: 0;
    font-weight: 700;
}

body .p-carousel .p-carousel-header .p-carousel-header-title {
    padding: 0;
}

body .p-carousel .p-carousel-viewport {
    border: 1px solid #c8c8c8;
    background-color: var(--background-color-white);
    color: var(--text-color-dark);
    padding: 0;
}

body .p-carousel .p-carousel-viewport .p-carousel-items .p-carousel-item {
    border: 1px solid #c8c8c8;
}

body .p-carousel .p-carousel-footer {
    background-color: #f4f4f4;
    color: var(--text-color-dark);
    padding: 0.571em 1em;
    border: 1px solid #c8c8c8;
    margin: 0;
}

body .p-carousel .p-carousel-button {
    color: #848484;
    -moz-transition: color 0.2s;
    -o-transition: color 0.2s;
    -webkit-transition: color 0.2s;
    transition: color 0.2s;
}

body .p-carousel .p-carousel-button.p-carousel-next-button:before {
    content: "";
}

body .p-carousel .p-carousel-button.p-carousel-prev-button:before {
    content: "";
}

body .p-carousel .p-carousel-button:not(.p-disabled):hover {
    color: var(--text-color-dark);
}

body .p-carousel .p-carousel-page-links {
    margin: 0.125em 0.5em;
}

body .p-carousel .p-carousel-page-links .p-carousel-page-link {
    color: #848484;
    -moz-transition: color 0.2s;
    -o-transition: color 0.2s;
    -webkit-transition: color 0.2s;
    transition: color 0.2s;
}

body .p-carousel .p-carousel-page-links .p-carousel-page-link:hover {
    color: var(--text-color-dark);
}

body .p-treetable .p-treetable-caption,
body .p-treetable .p-treetable-summary {
    background-color: #f4f4f4;
    color: var(--text-color-dark);
    border: 1px solid #c8c8c8;
    padding: 0.571em 1em;
    text-align: center;
}

body .p-treetable .p-treetable-caption {
    border-bottom: 0 none;
    font-weight: 700;
}

body .p-treetable .p-treetable-summary {
    border-top: 0 none;
    font-weight: 700;
}

body .p-treetable .p-treetable-thead>tr>th {
    padding: 0.571em 0.857em;
    border: 1px solid #c8c8c8;
    font-weight: 700;
    color: var(--text-color-dark);
    background-color: #f4f4f4;
}

body .p-treetable .p-treetable-tbody>tr>td {
    padding: 0.571em 0.857em;
}

body .p-treetable .p-treetable-tfoot>tr>td {
    padding: 0.571em 0.857em;
    border: 1px solid #c8c8c8;
    font-weight: 700;
    color: var(--text-color-dark);
    background-color: var(--background-color-white);
}

body .p-treetable .p-sortable-column .p-sortable-column-icon {
    color: #848484;
}

body .p-treetable .p-sortable-column:not(.p-highlight):hover {
    background-color: #e0e0e0;
    color: var(--text-color-dark);
}

body .p-treetable .p-sortable-column:not(.p-highlight):hover .p-sortable-column-icon {
    color: var(--text-color-dark);
}

body .p-treetable .p-sortable-column.p-highlight {
    background-color: #007ad9;
    color: var(--text-color-white);
}

body .p-treetable .p-sortable-column.p-highlight .p-sortable-column-icon {
    color: var(--background-color-table-header);
}

body .p-treetable .p-editable-column input {
    font-size: 14px;
    // font-family: "Open Sans", "Helvetica Neue", sans-serif;
}

body .p-treetable .p-editable-column input:focus {
    outline: 1px solid #007ad9;
    outline-offset: 2px;
}

body .p-treetable .p-treetable-tbody>tr {
    background-color: var(--background-color-white);
    color: var(--text-color-dark);
}

body .p-treetable .p-treetable-tbody>tr>td {
    background-color: inherit;
    border: 1px solid #c8c8c8;
}

body .p-treetable .p-treetable-tbody>tr>td .p-treetable-toggler {
    color: #848484;
    vertical-align: middle;
}

body .p-treetable .p-treetable-tbody>tr>td .p-treetable-chkbox {
    vertical-align: middle;
    margin-right: 0.5em;
}

body .p-treetable .p-treetable-tbody>tr.p-highlight {
    background-color: #007ad9;
    color: var(--text-color-white);
}

body .p-treetable .p-treetable-tbody>tr.p-highlight>td {
    background-color: inherit;
    border: 1px solid #c8c8c8;
}

body .p-treetable .p-treetable-tbody>tr.p-highlight>td .p-treetable-toggler {
    color: var(--text-color-white);
}

body .p-treetable .p-treetable-tbody>tr.p-contextmenu-selected {
    background-color: #007ad9;
    color: var(--text-color-white);
}

body .p-treetable .p-treetable-tbody>tr.p-contextmenu-selected>td {
    background-color: inherit;
    border: 1px solid #c8c8c8;
}

body .p-treetable .p-treetable-tbody>tr.p-contextmenu-selected>td .p-treetable-toggler {
    color: var(--text-color-white);
}

body .p-treetable.p-treetable-hoverable-rows .p-treetable-tbody>tr:not(.p-highlight):not(.p-contextmenu-selected):hover {
    cursor: pointer;
    background-color: #eaeaea;
    color: var(--text-color-dark);
}

body .p-treetable .p-column-resizer-helper {
    background-color: #007ad9;
}

body .p-messages {
    padding: 1em;
    margin: 1em 0;
}

body .p-messages.p-messages-info {
    background-color: #7fbcec;
    border: 0 none;
    color: #212121;
}

body .p-messages.p-messages-info .p-messages-icon {
    color: #212121;
}

body .p-messages.p-messages-info .p-messages-close {
    color: #212121;
}

body .p-messages.p-messages-success {
    background-color: #b7d8b7;
    border: 0 none;
    color: #212121;
}

body .p-messages.p-messages-success .p-messages-icon {
    color: #212121;
}

body .p-messages.p-messages-success .p-messages-close {
    color: #212121;
}

body .p-messages.p-messages-warn {
    background-color: #ffe399;
    border: 0 none;
    color: #212121;
}

body .p-messages.p-messages-warn .p-messages-icon {
    color: #212121;
}

body .p-messages.p-messages-warn .p-messages-close {
    color: #212121;
}

body .p-messages.p-messages-error {
    background-color: #f8b7bd;
    border: 0 none;
    color: #212121;
}

body .p-messages.p-messages-error .p-messages-icon {
    color: #212121;
}

body .p-messages.p-messages-error .p-messages-close {
    color: #212121;
}

body .p-messages .p-messages-close {
    top: 0.25em;
    right: 0.5em;
    font-size: 1.5em;
}

body .p-messages .p-messages-icon {
    font-size: 2em;
}

body .p-message {
    padding: 0.429em;
    margin: 0;
}

body .p-message.p-message-info {
    background-color: #7fbcec;
    border: 0 none;
    color: #212121;
}

body .p-message.p-message-info .p-message-icon {
    color: #212121;
}

body .p-message.p-message-success {
    background-color: #b7d8b7;
    border: 0 none;
    color: #212121;
}

body .p-message.p-message-success .p-message-icon {
    color: #212121;
}

body .p-message.p-message-warn {
    background-color: #ffe399;
    border: 0 none;
    color: #212121;
}

body .p-message.p-message-warn .p-message-icon {
    color: #212121;
}

body .p-message.p-message-error {
    background-color: #f8b7bd;
    border: 0 none;
    color: #212121;
}

body .p-message.p-message-error .p-message-icon {
    color: #212121;
}

body .p-message .p-message-icon {
    font-size: 1.25em;
}

body .p-message .p-message-text {
    font-size: 1em;
}

body .p-growl {
    top: 70px;
}

body .p-growl .p-growl-item-container {
    margin: 0 0 1em 0;
    opacity: 0.9;
    filter: alpha(opacity=90);
    -webkit-box-shadow: 0 $borderRadius 6px 0 rgba(0, 0, 0, 0.16);
    -moz-box-shadow: 0 $borderRadius 6px 0 rgba(0, 0, 0, 0.16);
    box-shadow: 0 $borderRadius 6px 0 rgba(0, 0, 0, 0.16);
}

body .p-growl .p-growl-item-container .p-growl-item {
    padding: 1em;
}

body .p-growl .p-growl-item-container .p-growl-item .p-growl-message {
    margin: 0 0 0 4em;
}

body .p-growl .p-growl-item-container .p-growl-item .p-growl-image {
    font-size: 2.571em;
}

body .p-growl .p-growl-item-container.p-growl-message-info {
    background-color: #7fbcec;
    border: 0 none;
    color: #212121;
}

body .p-growl .p-growl-item-container.p-growl-message-info .p-growl-image {
    color: #212121;
}

body .p-growl .p-growl-item-container.p-growl-message-success {
    background-color: #b7d8b7;
    border: 0 none;
    color: #212121;
}

body .p-growl .p-growl-item-container.p-growl-message-success .p-growl-image {
    color: #212121;
}

body .p-growl .p-growl-item-container.p-growl-message-warn {
    background-color: #ffe399;
    border: 0 none;
    color: #212121;
}

body .p-growl .p-growl-item-container.p-growl-message-warn .p-growl-image {
    color: #212121;
}

body .p-growl .p-growl-item-container.p-growl-message-error {
    background-color: #f8b7bd;
    border: 0 none;
    color: #212121;
}

body .p-growl .p-growl-item-container.p-growl-message-error .p-growl-image {
    color: #212121;
}

body .p-toast .p-toast-message {
    -webkit-box-shadow: 0 $borderRadius 6px 0 rgba(0, 0, 0, 0.16);
    -moz-box-shadow: 0 $borderRadius 6px 0 rgba(0, 0, 0, 0.16);
    box-shadow: 0 $borderRadius 6px 0 rgba(0, 0, 0, 0.16);
    margin: 0 0 1em 0;
}

body .p-toast .p-toast-message.p-toast-message-info {
    background-color: #7fbcec;
    border: 0 none;
    color: #212121;
}

body .p-toast .p-toast-message.p-toast-message-info .p-toast-close-icon {
    color: #212121;
}

body .p-toast .p-toast-message.p-toast-message-success {
    background-color: #b7d8b7;
    border: 0 none;
    color: #212121;
}

body .p-toast .p-toast-message.p-toast-message-success .p-toast-close-icon {
    color: #212121;
}

body .p-toast .p-toast-message.p-toast-message-warn {
    background-color: #ffe399;
    border: 0 none;
    color: #212121;
}

body .p-toast .p-toast-message.p-toast-message-warn .p-toast-close-icon {
    color: #212121;
}

body .p-toast .p-toast-message.p-toast-message-error {
    background-color: #f8b7bd;
    border: 0 none;
    color: #212121;
}

body .p-toast .p-toast-message.p-toast-message-error .p-toast-close-icon {
    color: #212121;
}

body .p-component-overlay {
    background-color: rgba(0, 0, 0, 0.4);
}

body .p-overlaypanel {
    background-color: var(--background-color-white);
    color: var(--text-color-dark);
    padding: 0;
    border: 1px solid #c8c8c8;
    -moz-border-radius: 0;
    -webkit-border-radius: 0;
    border-radius: 0;
}

body .p-overlaypanel .p-overlaypanel-content {
    padding: 0.571em 1em;
}

body .p-overlaypanel .p-overlaypanel-close {
    background-color: #007ad9;
    color: var(--text-color-white);
    width: 1.538em;
    height: 1.538em;
    line-height: 1.538em;
    text-align: center;
    position: absolute;
    top: -0.769em;
    right: -0.769em;
    -moz-transition: background-color 0.2s;
    -o-transition: background-color 0.2s;
    -webkit-transition: background-color 0.2s;
    transition: background-color 0.2s;
}

body .p-overlaypanel .p-overlaypanel-close:hover {
    background-color: #005b9f;
    color: var(--text-color-white);
}

body .p-overlaypanel .p-overlaypanel-close .p-overlaypanel-close-icon {
    line-height: inherit;
}

body .p-overlaypanel:after {
    border-color: rgba(255, 255, 255, 0);
    border-bottom-color: var(--text-color-white);
}

body .p-overlaypanel:before {
    border-color: rgba(200, 200, 200, 0);
    border-bottom-color: #c8c8c8;
}

body .p-overlaypanel.p-overlaypanel-flipped:after {
    border-top-color: var(--border-color-white);
}

body .p-overlaypanel.p-overlaypanel-flipped:before {
    border-top-color: #c8c8c8;
}

body .p-dialog {
    width: 50%;
    padding: 0;
    -webkit-box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.16);
    -moz-box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.16);
    box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.16);
}

body .p-dialog .p-dialog-header {
    border: 1px solid #c8c8c8;
    background-color: var(--background-color-dialog-title);
    color: var(--text-color-dark);
    padding: 1em;
    font-weight: 700;
    border-bottom: 1px solid var(--border-color-muted) !important;
    width: 100%;
    margin: 0px !important;
    //display: inline-block;
    font-family: $font-regular;
    font-size: 18px;
    text-transform: uppercase;
    ;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.13;
    letter-spacing: normal;
}

body .p-dialog .p-dialog-header .p-dialog-title {
    margin: 0;
    float: none;
}

body .p-dialog .p-dialog-header .p-dialog-header-icon {
    color: #848484;
    border: 0 none;
    padding: 0;
    margin-left: 0.5em;
    -moz-transition: color 0.2s;
    -o-transition: color 0.2s;
    -webkit-transition: color 0.2s;
    transition: color 0.2s;
}

body .p-dialog .p-dialog-header .p-dialog-header-icon:hover {
    color: var(--text-color-dark);
}

body .p-dialog .p-dialog-content {
    background-color: var(--background-color-white);
    color: var(--text-color-dark);
    border: 1px solid #c8c8c8;
    padding: 0.571em 1em;
}

body .p-dialog .p-dialog-footer {
    border: 1px solid #c8c8c8;
    background-color: var(--background-color-white);
    color: var(--text-color-dark);
    padding: 0.571em 1em;
    margin: 0;
    text-align: right;
    position: relative;
    top: -1px;
}

body .p-dialog .p-dialog-footer button {
    margin: 0 0.5em 0 0;
    width: auto;
}

body .p-dialog.p-confirm-dialog .p-dialog-content {
    padding: 1.5em;
}

body .p-dialog.p-confirm-dialog .p-dialog-content>span {
    float: none;
    display: inline-block;
    vertical-align: middle;
    line-height: 14px;
    margin: 0;
}

body .p-dialog.p-confirm-dialog .p-dialog-content>span.p-icon {
    margin-right: 0.35em;
    font-size: 16px;
}

body .p-sidebar {
    background-color: var(--background-color-white);
    color: var(--text-color-dark);
    padding: 0.571em 1em;
    border: 1px solid #c8c8c8;
    -webkit-box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.16);
    -moz-box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.16);
    box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.16);
}

body .p-sidebar .p-sidebar-close {
    color: #848484;
}

body .p-sidebar .p-sidebar-close:hover {
    color: var(--text-color-dark);
}

.tooltipStyle {

    min-width: 345px;
    max-width: 500px;
}

.p-tooltip .p-tooltip-text {
    background-color: var(--background-color-dark);
    color: var(--text-color-white);
    // min-width: 355px;
    // max-width: 500px;

    // text-align: justify;
    padding: 0.429em;
    -webkit-box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.16);
    -moz-box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.16);
    box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.16);
}

body .p-tooltip.p-tooltip-right .p-tooltip-arrow {
    border-right-color: var(--border-color-dark);
}

body .p-tooltip.p-tooltip-left .p-tooltip-arrow {
    border-left-color: var(--border-color-dark);
}

body .p-tooltip.p-tooltip-top .p-tooltip-arrow {
    border-top-color: var(--text-color-dark);
}

body .p-tooltip.p-tooltip-bottom .p-tooltip-arrow {
    border-bottom-color: var(--text-color-dark);
}

body .p-lightbox {
    -webkit-box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.16);
    -moz-box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.16);
    box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.16);
}

body .p-lightbox .p-lightbox-caption {
    border: 1px solid #c8c8c8;
    background-color: #f4f4f4;
    color: var(--text-color-dark);
    padding: 0.571em 1em;
    font-weight: 700;
}

body .p-lightbox .p-lightbox-caption .p-lightbox-caption-text {
    color: var(--text-color-dark);
    margin: 0;
}

body .p-lightbox .p-lightbox-caption .p-lightbox-close {
    padding: 0;
    color: #848484;
    -moz-transition: color 0.2s;
    -o-transition: color 0.2s;
    -webkit-transition: color 0.2s;
    transition: color 0.2s;
}

body .p-lightbox .p-lightbox-caption .p-lightbox-close:hover {
    color: var(--text-color-dark);
}

body .p-lightbox .p-lightbox-content-wrapper {
    overflow: hidden;
    background-color: var(--background-color-white);
    color: var(--text-color-dark);
    border: 0 none;
    padding: 0;
    -moz-border-radius: 0;
    -webkit-border-radius: 0;
    border-radius: 0;
}

body .p-lightbox .p-lightbox-content-wrapper .p-lightbox-nav-left,
body .p-lightbox .p-lightbox-content-wrapper .p-lightbox-nav-right {
    -moz-transition: all 0.2s;
    -o-transition: all 0.2s;
    -webkit-transition: all 0.2s;
    transition: all 0.2s;
    font-size: 3em;
    color: var(--text-color-white);
    margin-top: -0.5em;
}

body .p-lightbox .p-lightbox-content-wrapper .p-lightbox-nav-left:hover,
body .p-lightbox .p-lightbox-content-wrapper .p-lightbox-nav-right:hover {
    -webkit-transform: scale(1.2);
    -moz-transform: scale(1.2);
    -o-transform: scale(1.2);
    -ms-transform: scale(1.2);
    transform: scale(1.2);
}

body .p-lightbox .p-lightbox-content-wrapper .p-lightbox-content.p-lightbox-loading~a {
    display: none;
}

body .p-breadcrumb {
    background-color: var(--background-color-white);
    border: 1px solid #c8c8c8;
    padding: 0.571em 1em;
    
    li:hover{
        cursor: pointer!important;
    }
}

body .p-breadcrumb ul li .p-menuitem-link {
    color: var(--text-color-dark);
    margin: 0;
}

body .p-breadcrumb ul li.p-breadcrumb-chevron {
    margin: 0 0.5em 0 0.5em;
    color: #848484;
}

body .p-breadcrumb ul li:first-child a {
    color: #848484;
    margin: 0;
}

body .p-breadcrumb ul li .p-menuitem-icon {
    color: #848484;
}

body .p-steps {
    position: relative;
}

body .p-steps .p-steps-item {
    background-color: transparent;
    text-align: center;
}

body .p-steps .p-steps-item .p-menuitem-link {
    display: inline-block;
    text-align: center;
    background-color: transparent;
    overflow: hidden;
}

body .p-steps .p-steps-item .p-menuitem-link .p-steps-number {
    -moz-border-radius: 50%;
    -webkit-border-radius: 50%;
    border-radius: 50%;
    display: inline-block;
    color: var(--text-color-dark);
    background-color: var(--background-color-white);
    border: 1px solid #c8c8c8;
    position: relative;
    top: 16px;
    margin-bottom: 14px;
    width: 28px;
    height: 28px;
    font-size: 16px;
    line-height: 24px;
    text-align: center;
}

body .p-steps .p-steps-item .p-menuitem-link .p-steps-title {
    display: block;
    margin-top: 6px;
    color: #848484;
}

body .p-steps .p-steps-item.p-highlight .p-steps-number {
    background: var(--background-color-white);
    color: var(--text-color-dark);
    border-radius: inherit;
}

body .p-steps .p-steps-item.p-highlight .p-steps-title {
    font-weight: 700;
    color: var(--text-color-dark);
}

body .p-steps .p-steps-item:last-child .p-menuitem-link {
    display: block;
}

body .p-steps:before {
    content: " ";
    border-top: 1px solid #c8c8c8;
    width: 100%;
    top: 45%;
    left: 0;
    display: block;
    position: absolute;
}

body .p-menu .p-menuitem-link,
body .p-menubar .p-menuitem-link,
body .p-tieredmenu .p-menuitem-link,
body .p-contextmenu .p-menuitem-link,
body .p-megamenu .p-menuitem-link,
body .p-slidemenu .p-menuitem-link {
    padding: 0.714em 0.857em;
    color: var(--text-color-dark);
    font-weight: normal;
    -moz-border-radius: 0;
    -webkit-border-radius: 0;
    border-radius: 0;
}

body .p-menu .p-menuitem-link .p-menuitem-text,
body .p-menubar .p-menuitem-link .p-menuitem-text,
body .p-tieredmenu .p-menuitem-link .p-menuitem-text,
body .p-contextmenu .p-menuitem-link .p-menuitem-text,
body .p-megamenu .p-menuitem-link .p-menuitem-text,
body .p-slidemenu .p-menuitem-link .p-menuitem-text {
    color: var(--text-color-dark);
}

body .p-menu .p-menuitem-link .p-menuitem-icon,
body .p-menubar .p-menuitem-link .p-menuitem-icon,
body .p-tieredmenu .p-menuitem-link .p-menuitem-icon,
body .p-contextmenu .p-menuitem-link .p-menuitem-icon,
body .p-megamenu .p-menuitem-link .p-menuitem-icon,
body .p-slidemenu .p-menuitem-link .p-menuitem-icon {
    color: var(--text-color-dark);
    margin-right: 0.5em;
}

body .p-menu .p-menuitem-link:hover,
body .p-menubar .p-menuitem-link:hover,
body .p-tieredmenu .p-menuitem-link:hover,
body .p-contextmenu .p-menuitem-link:hover,
body .p-megamenu .p-menuitem-link:hover,
body .p-slidemenu .p-menuitem-link:hover {
    background-color: $background-color-hover;
}

body .p-menu .p-menuitem-link:hover .p-menuitem-text,
body .p-menubar .p-menuitem-link:hover .p-menuitem-text,
body .p-tieredmenu .p-menuitem-link:hover .p-menuitem-text,
body .p-contextmenu .p-menuitem-link:hover .p-menuitem-text,
body .p-megamenu .p-menuitem-link:hover .p-menuitem-text,
body .p-slidemenu .p-menuitem-link:hover .p-menuitem-text {
    color: var(--background-color-primary);
}

body .p-menu .p-menuitem-link:hover .p-menuitem-icon,
body .p-menubar .p-menuitem-link:hover .p-menuitem-icon,
body .p-tieredmenu .p-menuitem-link:hover .p-menuitem-icon,
body .p-contextmenu .p-menuitem-link:hover .p-menuitem-icon,
body .p-megamenu .p-menuitem-link:hover .p-menuitem-icon,
body .p-slidemenu .p-menuitem-link:hover .p-menuitem-icon {
    color: var(--background-color-primary);
}

body .p-menu {
    padding: 0;
    background-color: var(--background-color-white);
    border: 1px solid #c8c8c8;

    ul {
        width: 100%;
    }
}

body .p-menu .p-menuitem {
    margin: 0;
}

body .p-menu.p-shadow {
    border: 1px solid #c8c8c8;
    -webkit-box-shadow: 0 0px 6px 0 rgba(0, 0, 0, 0.16);
    -moz-box-shadow: 0 0px 6px 0 rgba(0, 0, 0, 0.16);
    box-shadow: 0 0px 6px 0 rgba(0, 0, 0, 0.16);
}

body .p-menu .p-submenu-header {
    margin: 0;
    padding: 0.714em 0.857em;
    color: var(--text-color-dark);
    background-color: #f4f4f4;
    font-weight: 700;
    border: 0 none;
}

body .p-menu .p-menu-separator {
    border: 1px solid #c8c8c8;
    border-width: 1px 0 0 0;
}

body .p-menubar {
    padding: 0;
    background-color: var(--background-color-white);
    border: 1px solid #c8c8c8;
}

body .p-menubar .p-menubar-root-list>.p-menuitem>.p-menuitem-link {
    padding: 0.714em 0.857em;
}

body .p-menubar .p-menubar-root-list>.p-menu-separator {
    border: 1px solid #c8c8c8;
    border-width: 0 0 0 1px;
}

body .p-menubar .p-submenu-list {
    padding: 0;
    background-color: var(--background-color-white);
    border: 1px solid #c8c8c8;
    -webkit-box-shadow: 0 0px 6px 0 rgba(0, 0, 0, 0.16);
    -moz-box-shadow: 0 0px 6px 0 rgba(0, 0, 0, 0.16);
    box-shadow: 0 0px 6px 0 rgba(0, 0, 0, 0.16);
}

body .p-menubar .p-submenu-list .p-menuitem {
    margin: 0;
}

body .p-menubar .p-submenu-list .p-menu-separator {
    border: 1px solid #c8c8c8;
    border-width: 1px 0 0 0;
}

body .p-menubar .p-menuitem {
    margin: 0;
}

body .p-menubar .p-menuitem.p-menuitem-active>.p-menuitem-link {
    background-color: #eaeaea;
}

body .p-menubar .p-menuitem.p-menuitem-active>.p-menuitem-link .p-menuitem-text {
    color: var(--text-color-dark);
}

body .p-menubar .p-menuitem.p-menuitem-active>.p-menuitem-link .p-menuitem-icon,
body .p-menubar .p-menuitem.p-menuitem-active>.p-menuitem-link .p-submenu-icon {
    color: var(--text-color-dark);
}

body .p-contextmenu {
    padding: 0;
    background-color: var(--background-color-white);
    border: 1px solid #c8c8c8;
    -webkit-box-shadow: 0 0px 6px 0 rgba(0, 0, 0, 0.16);
    -moz-box-shadow: 0 0px 6px 0 rgba(0, 0, 0, 0.16);
    box-shadow: 0 0px 6px 0 rgba(0, 0, 0, 0.16);
}

body .p-contextmenu .p-submenu-list {
    padding: 0;
    background-color: var(--background-color-white);
    border: 1px solid #c8c8c8;
    -webkit-box-shadow: 0 0px 6px 0 rgba(0, 0, 0, 0.16);
    -moz-box-shadow: 0 0px 6px 0 rgba(0, 0, 0, 0.16);
    box-shadow: 0 0px 6px 0 rgba(0, 0, 0, 0.16);
}

body .p-contextmenu .p-menuitem {
    margin: 0;
}

body .p-contextmenu .p-menuitem .p-menuitem-link .p-submenu-icon {
    right: 0.429em;
}

body .p-contextmenu .p-menuitem.p-menuitem-active>.p-menuitem-link {
    background-color: #eaeaea;
}

body .p-contextmenu .p-menuitem.p-menuitem-active>.p-menuitem-link .p-menuitem-text {
    color: var(--text-color-dark);
}

body .p-contextmenu .p-menuitem.p-menuitem-active>.p-menuitem-link .p-menuitem-icon,
body .p-contextmenu .p-menuitem.p-menuitem-active>.p-menuitem-link .p-submenu-icon {
    color: var(--text-color-dark);
}

body .p-contextmenu .p-menu-separator {
    border: 1px solid #c8c8c8;
    border-width: 1px 0 0 0;
}

body .p-tieredmenu {
    padding: 0;
    background-color: var(--background-color-white);
    border: 1px solid #c8c8c8;
}

body .p-tieredmenu .p-submenu-list {
    padding: 0;
    background-color: var(--background-color-white);
    border: 1px solid #c8c8c8;
    -webkit-box-shadow: 0 0px 6px 0 rgba(0, 0, 0, 0.16);
    -moz-box-shadow: 0 0px 6px 0 rgba(0, 0, 0, 0.16);
    box-shadow: 0 0px 6px 0 rgba(0, 0, 0, 0.16);
}

body .p-tieredmenu .p-menuitem {
    margin: 0;
}

body .p-tieredmenu .p-menuitem .p-menuitem-link .p-submenu-icon {
    right: 0.429em;
}

body .p-tieredmenu .p-menuitem.p-menuitem-active>.p-menuitem-link {
    background-color: #eaeaea;
}

body .p-tieredmenu .p-menuitem.p-menuitem-active>.p-menuitem-link .p-menuitem-text {
    color: var(--text-color-dark);
}

body .p-tieredmenu .p-menuitem.p-menuitem-active>.p-menuitem-link .p-menuitem-icon,
body .p-tieredmenu .p-menuitem.p-menuitem-active>.p-menuitem-link .p-submenu-icon {
    color: var(--text-color-dark);
}

body .p-tieredmenu .p-menu-separator {
    border: 1px solid #c8c8c8;
    border-width: 1px 0 0 0;
}

body .p-slidemenu {
    padding: 0;
    background-color: var(--background-color-white);
    border: 1px solid #c8c8c8;
}

body .p-slidemenu .p-submenu-list {
    padding: 0;
    background-color: var(--background-color-white);
    border: 0 none;
}

body .p-slidemenu .p-menuitem {
    margin: 0;
}

body .p-slidemenu .p-menuitem .p-menuitem-link .p-submenu-icon {
    right: 0.429em;
}

body .p-slidemenu .p-menuitem.p-menuitem-active>.p-menuitem-link {
    background-color: #eaeaea;
}

body .p-slidemenu .p-menuitem.p-menuitem-active>.p-menuitem-link .p-menuitem-text {
    // color: var(--text-color-dark);
    color: var(--text-color-dark);
}

body .p-slidemenu .p-menuitem.p-menuitem-active>.p-menuitem-link .p-menuitem-icon,
body .p-slidemenu .p-menuitem.p-menuitem-active>.p-menuitem-link .p-submenu-icon {
    // color: var(--text-color-dark);
    color: var(--text-color-dark);
}

body .p-slidemenu .p-slidemenu-backward {
    margin: 0;
    padding: 0.571em 1em;
    // color: var(--text-color-dark);
    color: var(--text-color-dark);
    // background-color: #f4f4f4;
    background-color: var(--background-color-white);
    font-weight: 700;
    border: 0 none;
}

body .p-slidemenu .p-slidemenu-backward:hover {
    background-color: #dbdbdb;
    // color: var(--text-color-dark);
    color: var(--text-color-dark);
}

body .p-slidemenu .p-menu-separator {
    border: 1px solid #c8c8c8;
    border-width: 1px 0 0 0;
}

body .p-tabmenu {
    background-color: transparent;
    border: 0 none;
}

body .p-tabmenu .p-tabmenu-nav {
    padding: 0;
    background: transparent;
    border: none;
}

body .p-tabmenu .p-tabmenu-nav .p-tabmenuitem {
    position: static;
    border: none;
    // background-color: #f4f4f4;
    background-color: var(--background-color-white);
    margin-right: 2px;
    margin-bottom: -1px;
    -moz-transition: background-color 0.2s;
    -o-transition: background-color 0.2s;
    -webkit-transition: background-color 0.2s;
    transition: background-color 0.2s;
}

body .p-tabmenu .p-tabmenu-nav .p-tabmenuitem .p-menuitem-link {
    // color: var(--text-color-dark);
    color: var(--text-color-dark);
    padding: 0.571em 1em;
}

body .p-tabmenu .p-tabmenu-nav .p-tabmenuitem .p-menuitem-link .p-menuitem-text {
    // color: var(--text-color-dark);
    color: var(--text-color-dark);
    margin-right: 0.5em;
}

body .p-tabmenu .p-tabmenu-nav .p-tabmenuitem .p-menuitem-link .p-menuitem-icon {
    color: #848484;
    margin-right: 0.5em;
}

body .p-tabmenu .p-tabmenu-nav .p-tabmenuitem:not(.p-highlight):not(.p-disabled):hover {
    background-color: #dbdbdb;
    border: none;
}

body .p-tabmenu .p-tabmenu-nav .p-tabmenuitem:not(.p-highlight):not(.p-disabled):hover .p-menuitem-link .p-menuitem-text {
    color: var(--text-color-dark);
}

body .p-tabmenu .p-tabmenu-nav .p-tabmenuitem:not(.p-highlight):not(.p-disabled):hover .p-menuitem-link .p-menuitem-icon {
    color: var(--text-color-dark);
}

body .p-tabmenu .p-tabmenu-nav .p-tabmenuitem.p-highlight {
    background-color: white;
    border: 1px solid;
    border-color: transparent transparent $border-active transparent;
}

body .p-tabmenu .p-tabmenu-nav .p-tabmenuitem.p-highlight .p-menuitem-link .p-menuitem-text {
    color: var(--text-color-dark);
}

body .p-tabmenu .p-tabmenu-nav .p-tabmenuitem.p-highlight .p-menuitem-link .p-menuitem-icon {
    color: var(--text-color-dark);
}

body .p-megamenu {
    padding: 0;
    background-color: var(--background-color-white);
    border: 1px solid #c8c8c8;
}

body .p-megamenu .p-megamenu-submenu-header {
    margin: 0;
    padding: 0.571em 1em;
    color: var(--text-color-dark);
    background-color: #f4f4f4;
    font-weight: 700;
    border: 0 none;
}

body .p-megamenu .p-megamenu-panel {
    padding: 0;
    background-color: var(--background-color-white);
    border: 1px solid #c8c8c8;
    -webkit-box-shadow: 0 0px 6px 0 rgba(0, 0, 0, 0.16);
    -moz-box-shadow: 0 0px 6px 0 rgba(0, 0, 0, 0.16);
    box-shadow: 0 0px 6px 0 rgba(0, 0, 0, 0.16);
}

body .p-megamenu .p-menuitem {
    margin: 0;
}

body .p-megamenu .p-menuitem .p-menuitem-link .p-submenu-icon {
    right: 0.429em;
}

body .p-megamenu .p-menuitem.p-menuitem-active>.p-menuitem-link {
    background-color: #eaeaea;
}

body .p-megamenu .p-menuitem.p-menuitem-active>.p-menuitem-link .p-menuitem-text {
    color: var(--text-color-dark);
}

body .p-megamenu .p-menuitem.p-menuitem-active>.p-menuitem-link .p-menuitem-icon,
body .p-megamenu .p-menuitem.p-menuitem-active>.p-menuitem-link .p-submenu-icon {
    color: var(--text-color-dark);
}

body .p-megamenu.p-megamenu-vertical .p-megamenu-root-list>.p-menuitem>.p-menuitem-link>.p-submenu-icon {
    right: 0.429em;
}

body .p-panelmenu .p-icon {
    position: static;
}

body .p-panelmenu .p-panelmenu-header {
    padding: 0;
}

body .p-panelmenu .p-panelmenu-header>a {
    border: 1px solid #c8c8c8;
    background-color: #f4f4f4;
    color: var(--text-color-dark);
    padding: 0.714em 0.857em;
    font-weight: 700;
    position: static;
    font-size: 14px;
    -moz-transition: background-color 0.2s;
    -o-transition: background-color 0.2s;
    -webkit-transition: background-color 0.2s;
    transition: background-color 0.2s;
}

body .p-panelmenu .p-panelmenu-header>a .p-panelmenu-icon {
    color: #848484;
}

body .p-panelmenu .p-panelmenu-header:not(.p-highlight)>a:hover {
    outline: 0 none;
    border: 1px solid #dbdbdb;
    background-color: #dbdbdb;
    color: var(--text-color-dark);
}

body .p-panelmenu .p-panelmenu-header:not(.p-highlight)>a:hover .p-panelmenu-icon {
    color: var(--text-color-dark);
}

body .p-panelmenu .p-panelmenu-header.p-highlight>a {
    border: 1px solid #007ad9;
    background-color: #007ad9;
    color: var(--text-color-white);
}

body .p-panelmenu .p-panelmenu-header.p-highlight>a .p-panelmenu-icon {
    color: var(--text-color-white);
}

body .p-panelmenu .p-panelmenu-header.p-highlight>a:hover {
    outline: 0 none;
    border: 1px solid #005b9f;
    background-color: #005b9f;
    color: var(--text-color-white);
}

body .p-panelmenu .p-panelmenu-header.p-highlight>a:hover .p-panelmenu-icon {
    color: var(--text-color-white);
}

body .p-panelmenu .p-panelmenu-panel {
    margin-top: 2px;
}

body .p-panelmenu .p-panelmenu-panel:first-child {
    margin-top: 0;
}

body .p-panelmenu .p-panelmenu-content {
    padding: 0;
    background-color: var(--background-color-white);
    border: 1px solid #c8c8c8;
    margin-top: 0;
    position: static;
    border-top: 0 none;
}

body .p-panelmenu .p-panelmenu-content .p-menuitem {
    margin: 0;
}

body .p-panelmenu .p-panelmenu-content .p-menuitem .p-menuitem-link {
    padding: 0.714em 0.857em;
    color: var(--text-color-dark);
}

body .p-panelmenu .p-panelmenu-content .p-menuitem .p-menuitem-link:hover {
    background-color: #eaeaea;
}

body .p-panelmenu .p-panelmenu-content .p-menuitem .p-menuitem-link:hover .p-menuitem-icon,
body .p-panelmenu .p-panelmenu-content .p-menuitem .p-menuitem-link:hover .p-panelmenu-icon {
    color: var(--text-color-dark);
}

body .p-panelmenu .p-panelmenu-content .p-menuitem .p-menuitem-link:hover .p-menuitem-text {
    color: var(--text-color-dark);
}

body .p-progressbar {
    border: 0 none;
    height: 24px;
    background-color: #eaeaea;
}

body .p-progressbar .p-progressbar-value {
    border: 0 none;
    margin: 0;
    background: var(--background-color-secondary);
}

body .p-progressbar .p-progressbar-label {
    margin-top: 0;
    color: var(--text-color-dark);
    line-height: 24px;
}

body .p-galleria .p-galleria-nav-prev,
body .p-galleria .p-galleria-nav-next {
    -moz-transition: all 0.2s;
    -o-transition: all 0.2s;
    -webkit-transition: all 0.2s;
    transition: all 0.2s;
    position: absolute;
    color: #848484;
}

body .p-galleria .p-galleria-nav-prev:hover,
body .p-galleria .p-galleria-nav-next:hover {
    -webkit-transform: scale(1.2);
    -moz-transform: scale(1.2);
    -o-transform: scale(1.2);
    -ms-transform: scale(1.2);
    transform: scale(1.2);
}

body .p-galleria .p-galleria-nav-prev {
    font-size: 16px;
}

body .p-galleria .p-galleria-nav-next {
    font-size: 16px;
}

body .p-terminal {
    background-color: var(--background-color-white);
    color: var(--text-color-dark);
    border: 1px solid #c8c8c8;
    padding: 0.571em 1em;
}

body .p-terminal .p-terminal-input {
    font-size: 14px;
    // font-family: "Open Sans", "Helvetica Neue", sans-serif;
    height: 16px;
}

body .p-terminal .p-terminal-command {
    height: 16px;
}

body .p-inplace {
    min-height: 2.357em;
}

body .p-inplace .p-inplace-display {
    padding: 0.429em;
    -moz-border-radius: $borderRadius;
    -webkit-border-radius: $borderRadius;
    border-radius: $borderRadius;
    -moz-transition: background-color 0.2s;
    -o-transition: background-color 0.2s;
    -webkit-transition: background-color 0.2s;
    transition: background-color 0.2s;
}

body .p-inplace .p-inplace-display:not(.p-disabled):hover {
    background-color: #eaeaea;
    color: var(--text-color-dark);
}

body .p-fluid .p-inplace.p-inplace-closable .p-inplace-content> :first-child {
    display: inline-block;
    width: calc(100% - 2.357em);
}


/* Add your customizations of theme here */

.bg-light {
    background-color: var(--background-color-white) !important;
}

.navbar-light {
    .nav-item {

        padding-top: 1rem;
        padding-bottom: 1rem;

        padding-left: 1.5rem;
        padding-right: 1.5rem;

        a {
            cursor: pointer;

            color: var(--text-color-dark) !important;

            &.nav-link {
                border-bottom: 2px solid transparent;

                &.active {
                    border-bottom: 2px solid var(--border-color-muted);
                }

                &:hover {
                    border-bottom: 2px solid var(--border-color-muted);
                }
            }
        }
    }
}


body {
    font-family: $font-regular;
    font-size: $font-size-md;

    // .fan-365-theme {
    //     .p-button-primary {
    //         border-radius: 20px;
    //         padding:0.25rem;
    //         border-color: var(--border-color-primary);

    //         &:hover {
    //             background-color: var(--background-color-white) !important;
    //             border-color: var(--border-color-muted) !important;
    //         }
    //     }
    // }

    .rounded-btn {
        border-radius: 50%;
    }

    .p-button-primary {
        background-color: var(--button-background-color-primary);
        border: 1px solid var(--border-color-secondary);
        font-family: $font-regular;
        font-size: $font-size-md;

        background-color: var(--button-background-color-primary);
        border: 1px solid var(--border-color-secondary);

        &:enabled:hover {
            border: 1px solid var(--border-color-secondary);
            background-color: var(--button-background-color-secondary);
            transition: background-color 0.15s ease-in-out;
        }
       
    }

    .p-button-secondary {
        background-color: var(--button-background-color-secondary);
        border: 1px solid var(--border-color-secondary);

        background-color: var(--button-background-color-secondary);
        border: 1px solid var(--border-color-secondary);

        &:enabled:hover {
            border: 1px solid var(--border-color-muted);
            background-color: var(--button-background-color-primary);
            transition: background-color 0.15s ease-in-out;
        }
        
    }

    .p-button-success {
        background-color: var(--button-background-color-success);
        border: 1px solid var(--border-color-success);

        background-color: var(--button-background-color-success);
        border: 1px solid var(--border-color-success);

        &:enabled:hover {
            border: 1px solid var(--border-color-success);
            background-color: var(--button-background-color-success);
        }
        
    }

    .p-button-info {
        background-color: var(--button-background-color-primary);
        border: 1px solid var(--border-color-muted);

        .p-button {
            background-color: var(--button-background-color-primary);
            border: 1px solid var(--border-color-muted);

            &:enabled:hover {
                border: 1px solid var(--border-color-muted);
                background-color: var(--button-background-color-primary);
            }
        }
    }

    .p-button-warning {
        background-color: var(--button-background-color-warning);
        border: 1px solid var(--border-color-warning);

        .p-button {
            background-color: var(--button-background-color-warning);
            border: 1px solid var(--border-color-warning);

            &:enabled:hover {
                border: 1px solid var(--border-color-warning);
                background-color: var(--button-background-color-warning);
            }
        }
    }

    .p-button-danger {
        background-color: var(--button-background-color-danger);
        border: 1px solid var(--border-color-danger);

        .p-button {
            background-color: var(--button-background-color-danger);
            border: 1px solid var(--border-color-danger);

            &:enabled:hover {
                border: 1px solid var(--border-color-danger);
                background-color: var(--button-background-color-danger);
            }
        }
    }

    .p-button {
        color: var(--text-color-white) !important;
        @include regular-text();

        &.p-button-primary {
            background-color: var(--button-background-color-primary);
            border: none;
            transition: background-color 0.1s ease-in-out;

            &:enabled:hover {
                border: none;
                background-color: var(--button-background-color-secondary);
            }
        }

        &.p-button-secondary {
            background-color: var(--button-background-color-secondary) !important;
            border: none;
            transition: background-color 0.1s ease-in-out;

            &:enabled:hover {
                border: none;
                background-color: var(--button-background-color-primary) !important;
            }
        }

        &.p-button-success {
            background-color: var(--button-background-color-success);
            border: none;

            &:enabled:hover {
                border: none;
                background-color: var(--button-background-color-success);
            }
        }

        &.p-button-info {
            background-color: var(--button-background-color-primary);
            border: none;

            &:enabled:hover {
                border: none;
                background-color: var(--button-background-color-primary);
            }
        }

        &.p-button-warning {
            background-color: var(--button-background-color-warning);
            border: none;

            &:enabled:hover {
                border: none;
                background-color: var(--button-background-color-warning);
            }
        }

        &.p-button-danger {
            background-color: var(--button-background-color-danger);
            border: none;

            &:enabled:hover {
                border: none;
                background-color: var(--button-background-color-danger);
            }
        }

        &:enabled:hover {
            //border: none;
            background-color: var(--background-color-primary);
        }
    }

    .text-primary {
        color: var(--text-color-primary) !important;
        @include regular-text();

    }

    .text-secondary {
        color: var(--text-color-secondary) !important;
        @include regular-text();
    }

    .text-danger {
        color: var(--text-color-danger) !important;
        @include regular-text();
    }

    .text-success {
        color: var(--text-color-success) !important;
        @include regular-text();
    }

    .text-muted {
        color: var(--text-color-muted) !important;
        @include regular-text();
    }

    .text-white {
        color: var(--text-color-white) !important;
        @include regular-text();
    }

    .text-dark {
        color: var(--text-color-dark) !important;
        @include regular-text();
    }

    .bg-primary {
        background-color: var(--background-color-primary) !important;
    }

    .bg-secondary {
        background-color: var(--background-color-secondary) !important;
    }

    .bg-danger {
        background-color: var(--background-color-danger) !important;
    }

    .bg-success {
        background-color: var(--background-color-success) !important;
    }

    .bg-muted {
        background-color: var(--background-color-muted) !important;
    }

    .bg-white {
        background-color: var(--background-color-white) !important;
    }

    .bg-dark {
        background-color: var(--background-color-dark) !important;
    }

    .btn-primary {
        background-color: var(--background-color-primary);
    }

    .input-bg-secondary {
        background-color: var(--input-color-secondary);
    }

    .link-color-secondary {
        color: var(--link-color-secondary);
    }

    .primary-navbar {
        background-color: var(--navbar-background-color);
        font-family: $font-regular;

        .navbar-brand {
            color: var(--navbar-text-color);
        }

        .navbar-nav {
            li>a {
                color: var(--navbar-text-color);
                cursor: pointer;
                font-size: $font-size-sm;
            }
        }
    }

    p-card {
        .p-card {
            background-color: var(--background-color-card);
            box-shadow: none;

            .p-card-body {
                padding: 1rem !important;
            }
        }
    }

    .card-body,
    .card {
        border-radius: 0;
    }

    p-pickList {
        .p-picklist-caption {
            background-color: var(--background-color-table-header) !important;
            padding: 0.5rem 1rem !important;
        }

        .p-picklist-listwrapper {
            border-radius: 0;
        }

        .p-picklist-item {
            color: var(--link-color-primary) !important;
            font-weight: 700;
            background-color: var(--background-color-white);

            &:hover,
            &:active,
            &:focus {
                background-color: var(--background-color-active) !important;
                box-shadow: none !important;
            }

            &.p-highlight {
                background-color: var(--background-color-active) !important;
                box-shadow: none !important;
            }
        }

        .p-picklist-caption {
            text-align: left !important;
        }

        .p-picklist-buttons {
            .p-button {
                height: 2rem !important;
                max-width: 3rem;
                //margin: auto;
                margin-bottom: 0.5rem;
                font-size: 1.5rem;
            }
        }

        .p-picklist-buttons {
                button:nth-child(1) {
                    background-color: var(--background-color-primary);
                    border: none;

                    &:hover {
                        background-color: var(--background-color-secondary) !important;
                    }
                }

                button:nth-child(3) {
                    background-color: var(--background-color-secondary);
                    border: none;

                    &:hover {
                        background-color: var(--background-color-primary) !important;
                    }
                }
        }

        .p-picklist-filter-container {
            padding: 0 !important;

            .p-picklist-filter-icon {
                left: 0 !important;
                padding-left: 0.5rem !important;
                color: var(--background-color-primary) !important;
                width: 2rem;
            }

            .p-picklist-filter {
                padding: 0.5rem;
                padding-left: 2rem;
            }
        }
    }

    rc-grid {
        .p-table {

            .p-table-thead {
                th {
                    padding: 0.75rem !important;
                }
            }

            .p-table-tbody {
                tr {
                    td {
                        font-size: 0.75rem !important;
                        padding: 0.75rem !important;
                    }
                }

                tr:hover {
                    background-color: var(--table-row-hover-color);
                    cursor: pointer;
                }
            }
        }
    }

    .truncate {
        width: 100%;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }

    .table-link {
        color: var(--link-color-primary);
    }

    .display-1 {
        @include display-1();
    }

    .display-2 {
        @include display-2();
    }

    .display-3 {
        @include display-3();
    }

    .display-4 {
        @include display-4();
    }

    .display-5 {
        @include display-5();
    }

    .p-scrollpanel-content {
       // padding-top: 9px !important;
    }

    .stepper-scroll {
        padding-top: 0 !important;
        left: 0;
    }
}

@media(max-width: 991px) {
    .p-scrollpanel-content {
        left: 0;
        padding-top: 9px !important;
    }

    .stepper-scroll {
        padding-top: 0 !important;
    }
}

.invalid-message-box {
    background-color: var(--color-red-lighten-1) !important;
    color: var(--color-red-lighten) !important;

    label {
        color: var(--color-red-lighten) !important;
        font-size: 16px;
        padding-top: 1px;
        padding-bottom: 1px;
    }
}