@import '_variables-shared.scss';

@mixin regular-text {
    font-family: $font-regular;
    font-size: $font-size-md;
    font-weight: 500;
}

@mixin table-header-text {
    font-size: $font-size-md;
    font-family: $font-regular;
    font-weight: 500;
    color: var(--text-color-dark);
}

@mixin table-body-text {
    font-size: $font-size-md;
    font-family: $font-regular;
    font-weight: 500;
    color: var(--text-color-dark);
}

@mixin sub-heading-text {
    font-size: $font-size-sm;
    font-family: $font-regular;
    font-weight: 500;
}

@mixin display-1 {
    font-family: $font-regular;
    font-size: $font-size-sm;
    font-weight: 500;
}

@mixin display-2 {
    font-family: $font-regular;
    font-size: $font-size-md;
    font-weight: 500;
}

@mixin display-3 {
    font-family: $font-regular;
    font-size: $font-size-lg;
    font-weight: 500;
}

@mixin display-4 {
    font-family: $font-bold;
    font-size: $font-size-xl;
    font-weight: 500;
}

@mixin display-5 {
    font-family: $font-bold;
    font-size: $font-size-xxl;
    font-family: 500;
}

@mixin nav-item-text {
    font-family: $font-regular;
    font-size: $font-size-sm;
    font-weight: 500;
}

@mixin sub-heading-text {
    font-family: $font-regular;
    font-size: $font-size-xs;
    font-weight: 500;
}